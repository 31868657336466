import React from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { AccountNotVerified } from '../account/AccountSettings';
import Dashboard from './Dashboard';
import GoLiveStepper from './GoLiveStepper';
import PendingActions from './PendingActions';
import TodayMetrics from './TodayMetrics';

import withPageView from '../../components/withPageView';
import VisualDataSvg from '../../components/svg/VisualData';

import { HOME } from '../../routes';

const Metrics = compose(
	connect(({ session: { company: { isActive } } }) => ({ isActive })),
	branch(
		({ isActive }) => !isActive,
		renderComponent(() => (
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					flex: 1,
					flexDirection: 'column',
					justifyContent: 'space-evenly',
				}}
			>
				<Typography align="center" variant="display1">
					Active your account to start your customer service and see
					metrics
				</Typography>
				<VisualDataSvg />
			</div>
		))
	)
)(() => (
	<>
		<div style={{ display: 'flex', marginBottom: '16px' }}>
			<div style={{ marginRight: '8px', width: '25%' }}>
				<TodayMetrics />
			</div>
			<div style={{ width: '25%' }}>
				<PendingActions />
			</div>
		</div>
		<Dashboard />
	</>
));

const Home = () => (
	<div
		style={{
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			overflowY: 'scroll',
			padding: '16px',
		}}
	>
		<AccountNotVerified />
		<GoLiveStepper />
		<Metrics />
	</div>
);

export default withPageView(HOME)(Home);

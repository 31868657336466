import React from 'react';
import { branch, compose, renderNothing, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';

import ContainedButton from '../../components/buttons/ContainedButton';

import { httpAction, sessionActions } from '../../actions';
import { Company } from '../../models';
import { FIELDS, SETTINGS, WORKFLOWS } from '../../routes';

const STEPS = [
	{
		label: 'Complete your app profile',
		optional: true,
		path: SETTINGS,
		isStepComplete: ({ isProfileComplete }) => isProfileComplete,
	},
	{
		label: 'Create some fields',
		optional: true,
		path: FIELDS,
		isStepComplete: ({ hasFields }) => hasFields,
	},
	{
		label: 'Create a workflow',
		path: WORKFLOWS,
		isStepComplete: ({ hasWorkflow }) => hasWorkflow,
	},
];

const GoLiveStepper = ({ activable, canActive, history, onGoLiveCompany }) => (
	<div
		style={{
			alignItems: 'center',
			backgroundColor: '#fff',
			display: 'flex',
			marginBottom: '16px',
		}}
	>
		<Stepper alternativeLabel nonLinear style={{ flex: 1 }}>
			{STEPS.map(({ isStepComplete, label, optional, path }, index) => (
				<Step active key={`step_${index}`}>
					<StepButton
						completed={isStepComplete(activable)}
						onClick={() => history.push(path)}
						optional={
							optional && (
								<Typography variant="caption">
									Optional
								</Typography>
							)
						}
					>
						{label}
					</StepButton>
				</Step>
			))}
		</Stepper>
		<ContainedButton
			disabled={!canActive}
			onClick={onGoLiveCompany}
			style={{ marginRight: '16px' }}
		>
			GO LIVE
		</ContainedButton>
	</div>
);

export default compose(
	withRouter,
	connect(
		({
			session: {
				activable: { canActive, ...activable },
				company,
			},
		}) => ({
			activable,
			canActive,
			company,
		}),
		dispatch =>
			bindActionCreators(
				{
					httpAction,
					setCurrentCompany: sessionActions.setCurrentCompany,
				},
				dispatch
			)
	),
	branch(({ company: { isActive } }) => isActive, renderNothing),
	withHandlers({
		onGoLiveCompany: ({ company, httpAction, setCurrentCompany }) => () =>
			httpAction({
				action: () =>
					Company.post({
						action: 'active',
						id: company.id,
					}),
				successCb: () =>
					setCurrentCompany({
						...company,
						isActive: true,
					}),
				successMessage: `${
					company.name
				} is now live in the Yanklee app`,
			}),
	})
)(GoLiveStepper);

import React, { Fragment } from 'react';
import {
	compose,
	withProps,
	withState,
	withStateHandlers,
	withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import uid from 'uid';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import { canSaveChoice } from '../StepCreator';
import { CHOICE_SEPARATOR, STEP_SEPARATOR } from '../utils';

const ChoiceItem = compose(
	translate('y'),
	connect(({ session: { company: { languages } } }) => ({
		languages,
	})),
	withHandlers({
		updateChoice: ({ choice, choices, updateChoices }) => (type, value) => {
			updateChoices(
				choices.map(
					c =>
						c.uid === choice.uid ? { ...choice, [type]: value } : c
				)
			);
		},
	}),
	withState('slugError', 'setSlugError', ''),
	withHandlers({
		updateChoiceSlug: ({ setSlugError, t, updateChoice }) => slug => {
			const slugError =
				slug.includes(CHOICE_SEPARATOR) || slug.includes(STEP_SEPARATOR)
					? t('fields.step.slug.separators')
					: '';
			setSlugError(slugError);
			updateChoice('slug', slug);
		},
	}),
	withProps(({ choice: { uid, ...choice }, collapsedUid, languages }) => ({
		collapsed: uid === collapsedUid,
		hasError: !canSaveChoice(choice, languages),
	}))
)(
	({
		choice: { uid, slug, labels },
		collapsed,
		hasError,
		languages,
		removeChoice,
		setCollapsedUid,
		slugError,
		updateChoice,
		updateChoiceSlug,
	}) => (
		<Fragment>
			<ListItem>
				<ListItemIcon
					onClick={() => setCollapsedUid(uid)}
					style={{ cursor: 'pointer' }}
				>
					<div>
						{collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						<MenuIcon style={{ cursor: 'pointer' }} />
					</div>
				</ListItemIcon>
				{slug}
				<ListItemSecondaryAction>
					{hasError && (
						<Tooltip title={'Some required params are missing'}>
							<ErrorOutlineIcon
								style={{ color: 'red', marginRight: '16px' }}
							/>
						</Tooltip>
					)}
					<Tooltip title={'Delete choice'}>
						<DeleteIcon
							onClick={() => removeChoice(uid)}
							style={{
								cursor: 'pointer',
							}}
						/>
					</Tooltip>
				</ListItemSecondaryAction>
			</ListItem>
			<Collapse in={collapsed}>
				<div style={{ display: 'flex' }}>
					<TextField
						error={!!slugError}
						helperText={slugError}
						label="Slug"
						onChange={({ target: { value } }) =>
							updateChoiceSlug(value)
						}
						required
						style={{ marginRight: '16px' }}
						value={slug}
					/>
					{languages.map(lng => (
						<TextField
							key={`choice_label_${lng}`}
							label={`Label ${lng}`}
							onChange={({ target: { value } }) =>
								updateChoice('labels', {
									...labels,
									[lng]: value,
								})
							}
							required
							style={{ marginRight: '16px' }}
							value={labels[lng] || ''}
						/>
					))}
				</div>
			</Collapse>
		</Fragment>
	)
);

const MAX_CHOICES = 5;

const ChoicesCreator = ({
	addChoice,
	choices,
	collapsedUid,
	removeChoice,
	setCollapsedUid,
	updateChoices,
}) => (
	<List>
		{choices &&
			choices.map(choice => (
				<ChoiceItem
					choice={choice}
					choices={choices}
					collapsedUid={collapsedUid}
					key={choice.uid}
					removeChoice={removeChoice}
					setCollapsedUid={setCollapsedUid}
					updateChoices={updateChoices}
				/>
			))}
		<ListItem
			button
			disabled={choices.length === MAX_CHOICES}
			onClick={() => addChoice(uid())}
		>
			<ListItemIcon>
				<AddCircleOutlineIcon />
			</ListItemIcon>
			<ListItemText
				primary="Add a choice"
				secondary={`You can add up to ${MAX_CHOICES} choices`}
			/>
		</ListItem>
	</List>
);

export default compose(
	withProps(({ updateStep }) => ({
		updateChoices: choices => updateStep('choices', choices),
	})),
	withStateHandlers(
		{
			collapsedUid: null,
		},
		{
			addChoice: (state, { choices, updateChoices }) => uid => {
				updateChoices([...choices, { uid, slug: '', labels: {} }]);
				return {
					collapsedUid: uid,
				};
			},
			removeChoice: (
				{ collapsedUid },
				{ choices, updateChoices }
			) => uid => {
				updateChoices(choices.filter(choice => choice.uid !== uid));
				return {
					collapsedUid: uid === collapsedUid ? null : uid,
				};
			},
			setCollapsedUid: ({ collapsedUid }) => uid => ({
				collapsedUid: uid === collapsedUid ? null : uid,
			}),
		}
	)
)(ChoicesCreator);

import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

const PendingActions = () => (
	<Paper>
		<List disablePadding>
			<ListItem button>
				<ListItemText primary="New messages" />
			</ListItem>
		</List>
	</Paper>
);

export default PendingActions;

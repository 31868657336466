import React from 'react';

import ChatSettings from '../ChatSettings';
import ContactSettings from './ContactSettings';
import GlobalSettings from './GlobalSettings';
import PhoneSettings from '../PhoneSettings';

export default () => (
	<>
		<div style={{ marginBottom: '16px' }}>
			<GlobalSettings />
		</div>
		<div style={{ marginBottom: '16px' }}>
			<ChatSettings />
		</div>
		<div style={{ marginBottom: '16px' }}>
			<PhoneSettings />
		</div>
		<div style={{ marginBottom: '16px' }}>
			<ContactSettings />
		</div>
	</>
);

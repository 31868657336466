/* import firebase from 'firebase';
import 'firebase/firestore'; */
import Cookies from 'js-cookie';
import { Client } from 'twilio-chat';

import {
	CAN_ACTIVE_COMPANY,
	CLEAR_SESSION,
	HAS_INIT_TWILIO,
	SET_CURRENT_COMPANY,
	SET_CURRENT_CHANNEL,
	SET_CHANNELS,
	SET_CURRENT_USER,
	SET_TWILIO_CLIENT,
	// SET_FIREBASE_COLLECTION,
	SET_PENDING_WORKFLOW,
} from './action-types';

import { Company, User } from '../models';

/* const FIREBASE_CONFIG = {
	apiKey: ,
	authDomain: ,
	databaseURL: ,
	projectId: ,
	storageBucket: ,
	messagingSenderId: ,
}; */

/* const getFirestore = () => (dispatch, getState) => {
	const companyId = getState().session.company.id;
	firebase.initializeApp(FIREBASE_CONFIG);
	const db = firebase.firestore();
	dispatch({
		type: SET_FIREBASE_COLLECTION,
		data: db.collection(String(companyId)),
	});
}; */

const { REACT_APP_API_BASE_URL } = process.env;

const TTL_COOKIE_TOKEN = 1;
const TTL_TOKEN = 60 * 60 * 24;

const setCurrentCompany = company => ({
	data: company,
	type: SET_CURRENT_COMPANY,
});

const setCurrentUser = user => ({
	data: user,
	type: SET_CURRENT_USER,
});

const setTwilioClient = client => ({
	data: client,
	type: SET_TWILIO_CLIENT,
});

const fetchTwilioToken = async company => {
	try {
		const res = await fetch(
			`${REACT_APP_API_BASE_URL}/twilio/token?companyId=${
				company.id
			}&identity=${company.slug}`
		);
		const { token } = await res.json();
		return token;
	} catch (e) {
		console.log(e);
	}
};

const initTwilio = company => async dispatch => {
	const token = await fetchTwilioToken(company);
	if (token) {
		const client = await Client.create(token);
		dispatch(setTwilioClient(client));
		client.on('tokenAboutToExpire', async () => {
			const token = await fetchTwilioToken(company);
			client.updateToken(token);
		});
	}
	dispatch({
		type: HAS_INIT_TWILIO,
	});
};

const canActiveCompany = companyId => async dispatch => {
	try {
		const activable = await Company.get({
			action: 'can-active',
			id: companyId,
		});
		dispatch({
			type: CAN_ACTIVE_COMPANY,
			data: activable,
		});
	} catch (e) {
		console.error(e);
	}
};

const startSession = () => async dispatch => {
	const token = Cookies.get('access_token');
	try {
		const res = await fetch(`${REACT_APP_API_BASE_URL}/account/session`, {
			headers: {
				Authorization: token,
			},
		});
		const { company, user } = await res.json();
		dispatch(setCurrentUser(user));
		if (company) {
			dispatch(setCurrentCompany(company));
			dispatch(initTwilio(company));
			if (!company.isActive) {
				dispatch(canActiveCompany(company.id));
			}
		}
	} catch (e) {
		console.error(e);
	}
};

const login = async (mail, pwd) => {
	const { id: token } = await User.post({
		action: 'login',
		data: {
			email: mail,
			password: pwd,
			ttl: TTL_TOKEN,
		},
	});
	Cookies.set('access_token', token, {
		expires: TTL_COOKIE_TOKEN,
	});
};

export default {
	clearSession: () => ({
		type: CLEAR_SESSION,
	}),
	initTwilio,
	login,
	setCurrentCompany,
	setCurrentUser,
	setCurrentChannel: currentChannel => ({
		data: currentChannel,
		type: SET_CURRENT_CHANNEL,
	}),
	setChannels: channels => ({
		data: channels,
		type: SET_CHANNELS,
	}),
	setTwilioClient,
	setPendingWorkflow: pendingWorkflow => ({
		data: pendingWorkflow,
		type: SET_PENDING_WORKFLOW,
	}),
	startSession,
	// getFirestore,
};

import React from 'react';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { translate } from 'react-i18next';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import ContainedButton from '../../components/buttons/ContainedButton';
import { Company } from '../../models';

const FIELD_TYPES = {
	string: 'String',
	int: 'Integer',
	mail: 'Mail',
};

const DEFAULT_FIELD_TYPE = 'string';

const FieldForm = ({
	company: { languages },
	slug,
	labels,
	type,
	global,
	required,
	errors,
	handleChange,
	handleLabelsChange,
	saveField,
}) => (
	<form style={{ display: 'flex', flexDirection: 'column' }}>
		<TextField
			margin="dense"
			id="slug"
			label="Slug"
			error={!!errors.slug}
			helperText={errors.slug || ''}
			value={slug}
			onChange={({ target: { value } }) => handleChange('slug', value)}
		/>
		<TextField
			select
			label="Type"
			margin="dense"
			inputProps={{
				name: 'type',
				id: 'type',
			}}
			onChange={({ target: { value } }) => handleChange('type', value)}
			value={type}
		>
			{Object.keys(FIELD_TYPES).map((key, index) => (
				<MenuItem key={index} value={key}>
					{FIELD_TYPES[key]}
				</MenuItem>
			))}
		</TextField>
		{languages.map(lng => (
			<TextField
				key={lng}
				margin="dense"
				id="label"
				label={`Label ${lng}`}
				error={errors.labels && errors.labels[lng]}
				helperText={errors.labels ? errors.labels[lng] : ''}
				value={labels[lng]}
				onChange={({ target: { value } }) =>
					handleLabelsChange(lng, value)
				}
			/>
		))}
		<FormGroup row>
			<FormControlLabel
				control={
					<Checkbox
						checked={global}
						onChange={({ target: { checked } }) => {
							handleChange('global', checked);
							handleChange('required', false);
						}}
					/>
				}
				label="Global"
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={required}
						onChange={({ target: { checked } }) =>
							handleChange('required', checked)
						}
					/>
				}
				disabled={!global}
				label="Required"
			/>
		</FormGroup>
		<ContainedButton onClick={saveField}>SAVE</ContainedButton>
	</form>
);

export default compose(
	translate('y'),
	withStateHandlers(
		({
			company: { languages },
			field: {
				id,
				slug = '',
				labels = languages.reduce(
					(acc, lng) => ({ ...acc, [lng]: '' }),
					{}
				),
				type = DEFAULT_FIELD_TYPE,
				global = false,
				required = false,
			} = {},
		}) => ({
			id,
			slug,
			labels,
			type,
			global,
			required,
			errors: {},
		}),
		{
			handleChange: () => (name, value) => ({
				[name]: value,
			}),
			handleLabelsChange: ({ labels }) => (lng, value) => ({
				labels: {
					...labels,
					[lng]: value,
				},
			}),
			updateErrors: () => errors => ({
				errors,
			}),
		}
	),
	withHandlers({
		saveField: ({
			company: { id: companyId, languages },
			fieldsSlug,
			slug,
			labels,
			type,
			global,
			required,
			id,
			onSave,
			t,
			updateErrors,
		}) => async () => {
			const errors = {};
			if (!slug) {
				errors.slug = t('fields.required');
			} else if (!id && !!fieldsSlug.includes(slug)) {
				errors.slug = t('fields.field.slugExists');
			}
			const emptyLabels = languages.filter(lng => !labels[lng]);
			if (emptyLabels.length) {
				errors.labels = emptyLabels.reduce(
					(acc, lng) => ({
						...acc,
						[lng]: t('fields.required'),
					}),
					{}
				);
			}
			updateErrors(errors);
			if (!Object.keys(errors).length) {
				await Company.post({
					action: 'fields',
					data: {
						slug,
						labels,
						type,
						global,
						required,
					},
					id: companyId,
					nestedId: id,
				});
				onSave();
			}
		},
	})
)(FieldForm);

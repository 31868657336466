import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

const ViewWrapper = ({ children }) => (
	<div
		style={{
			alignItems: 'center',
			border: '1px dashed #3f51b5',
			borderRadius: '3px',
			padding: '8px',
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
			justifyContent: 'center',
		}}
	>
		{children}
	</div>
);

export const SelectStepView = () => (
	<ViewWrapper>
		<Typography
			style={{ margin: 'auto', textAlign: 'center' }}
			variant="title"
		>
			Select a step to continue workflow
		</Typography>
	</ViewWrapper>
);

export const StartView = () => (
	<ViewWrapper>
		<Typography style={{ marginBottom: '8px' }} variant="title">
			Start your workflow
		</Typography>
		<Typography
			style={{
				textAlign: 'center',
			}}
			variant="caption"
		>
			Click on a step beside to start workflow
		</Typography>
	</ViewWrapper>
);

export const ValidWorkflowView = () => (
	<ViewWrapper>
		<Typography style={{ marginBottom: '8px' }} variant="title">
			Your workflow is valid
		</Typography>
		<Typography
			style={{
				textAlign: 'center',
			}}
			variant="caption"
		>
			Save to publish it or select a step in tree to edit
		</Typography>
	</ViewWrapper>
);

const AddStepView = () => (
	<Fragment>
		<ViewWrapper>
			<Typography style={{ marginBottom: '8px' }} variant="title">
				Add a step
			</Typography>
			<Typography style={{ textAlign: 'center' }} variant="caption">
				Click on a step beside to continue workflow
			</Typography>
		</ViewWrapper>
		<div style={{ margin: '16px 0', textAlign: 'center' }}>
			<Typography>OR</Typography>
		</div>
		<ViewWrapper>
			<Typography style={{ marginBottom: '8px' }} variant="title">
				Launch chat session
			</Typography>
			<Typography style={{ textAlign: 'center' }} variant="caption">
				The workflow will end at this step and a chat session will be
				launch
			</Typography>
		</ViewWrapper>
	</Fragment>
);

export default AddStepView;

export const STEP_TYPES = {
	CHAT: 'chat',
	CHOICES: 'choices',
	FORM: 'form',
};

export const CHOICE_SEPARATOR = '^';
export const STEP_SEPARATOR = '.';

export const constructChatStepKey = path =>
	`${path}${STEP_SEPARATOR}${STEP_TYPES.CHAT}`;
export const constructEmptyStepKey = path => `${path}${STEP_SEPARATOR}`;
export const constructStepKey = id => path =>
	path ? `${path}${STEP_SEPARATOR}${id}` : `${id}`;
export const constructStepChoiceKey = slug => id => path =>
	`${constructStepKey(id)(path)}${CHOICE_SEPARATOR}${slug}`;

export const constructExtandedStepKey = ({ choices, id, type }) => path =>
	type === STEP_TYPES.CHAT
		? []
		: type === STEP_TYPES.FORM
			? [`${path}${id}`]
			: [
					`${path}${id}`,
					...choices.map(
						({ slug }) => `${path}${id}${CHOICE_SEPARATOR}${slug}`
					),
			  ];

export const constructExtandedStepKeysFromTree = steps => workflowTree => (
	path = ''
) => {
	if (workflowTree === STEP_TYPES.CHAT) return [];

	return Object.keys(workflowTree).reduce((acc, key) => {
		const step = steps.find(({ id }) => id === parseInt(key, 10));
		const extandedKeys = constructExtandedStepKey(step)(path);

		return [
			...acc,
			...extandedKeys,
			...(step.type === STEP_TYPES.CHOICES
				? step.choices.reduce(
						(acc, { slug }) => [
							...acc,
							...constructExtandedStepKeysFromTree(steps)(
								workflowTree[key][slug]
							)(
								`${path}${key}${CHOICE_SEPARATOR}${slug}${STEP_SEPARATOR}`
							),
						],
						[]
				  )
				: constructExtandedStepKeysFromTree(steps)(workflowTree[key])(
						`${path}${key}${STEP_SEPARATOR}`
				  )),
		];
	}, []);
};

export const makeNextPathFromPendingStep = ({ choices, id, type }) => path =>
	type === STEP_TYPES.CHAT
		? null
		: type === STEP_TYPES.FORM
			? `${path}${id}${STEP_SEPARATOR}`
			: `${path}${id}${CHOICE_SEPARATOR}${
					choices[0].slug
			  }${STEP_SEPARATOR}`;

export const makePreviewPathOnRemoveStep = path => {
	const newParts = path.split(STEP_SEPARATOR).slice(0, -1);

	if (!newParts.length) return '';

	return `${newParts.join(STEP_SEPARATOR)}${STEP_SEPARATOR}`;
};

export const getStepFromPath = steps => path => {
	const lastPart = path.split(STEP_SEPARATOR).slice(-1)[0];

	if (!lastPart) return null;

	if (lastPart === STEP_TYPES.CHAT) return { type: STEP_TYPES.CHAT };

	const stepId = parseInt(lastPart.split(CHOICE_SEPARATOR)[0], 10);
	return steps.find(({ id }) => id === stepId);
};

// workflow updaters
export const canDeleteStep = workflow => previewStepPath => {
	const [first, ...path] = previewStepPath.split(/[.^]+/);
	// empty step case
	if (path.length && !path[path.length - 1]) return false;

	// get the preview step slice
	let slice = workflow[first];
	for (let i = 0; i < path.length; i++) {
		if (path[i]) slice = slice[path[i]];
	}

	if (!slice) return true;

	// preview step has chat children
	if (slice === STEP_TYPES.CHAT) {
		return false;
	}

	const sliceKeys = Object.keys(slice);

	// its a field step
	if (sliceKeys.length < 2) {
		return !sliceKeys.length;
	}

	// else its a choice step
	return Object.keys(slice).reduce(
		(acc, key) =>
			acc &&
			typeof slice[key] === 'object' &&
			!Object.keys(slice[key]).length,
		true
	);
};

const constructWorkflowSlice = ({ choices, id, type }) => {
	switch (type) {
		case STEP_TYPES.CHAT:
			return type;
		case STEP_TYPES.CHOICES:
			return {
				[id]: choices.reduce(
					(acc, choice) => ({ ...acc, [choice.slug]: {} }),
					{}
				),
			};
		case STEP_TYPES.FORM:
			return { [id]: {} };
		default:
			// FIXME: better handling of unknown step type
			// though it shouldn't happen
			throw new Error('unknown step type');
	}
};

export const addStepToWorkflow = (pendingStep = {}, workflow) => path => {
	const newWorkflowSlice = constructWorkflowSlice(pendingStep);

	if (!path) return newWorkflowSlice;

	const parts = path.split(/[.^]+/);

	let slice = workflow;
	for (let i = 0; i < parts.length - 2; i++) {
		slice = slice[parts[i]];
	}
	slice[parts[parts.length - 2]] = newWorkflowSlice;

	return workflow;
};

export const removeStepFromWorkflow = workflow => path => {
	// FIXME: replace separators in regex by variables
	const parts = path.split(/[.^]+/);

	if (parts.length === 1) return {};

	let slice = workflow;
	for (let i = 0; i < parts.length - 2; i++) {
		slice = slice[parts[i]];
	}
	slice[parts[parts.length - 2]] = {};

	return workflow;
};

export const hasEmptySliceWorkflow = workflow => {
	const workflowKeys = Object.keys(workflow);
	return (
		!workflowKeys.length ||
		workflowKeys.reduce(
			(acc, key) =>
				acc ||
				(typeof workflow[key] === 'object' &&
					hasEmptySliceWorkflow(workflow[key])),
			false
		)
	);
};

export const computeCurrentStepDepth = path =>
	path &&
	!!path.split(STEP_SEPARATOR)[0] &&
	path.split(STEP_SEPARATOR).length;

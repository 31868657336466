import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';

const FieldsList = ({ editField, fields, languages }) => (
	<Paper>
		<Table>
			<TableHead>
				<TableRow>
					{/* <TableCell numeric>ID</TableCell> */}
					<TableCell>Slug</TableCell>
					{languages.map(lng => (
						<TableCell key={`header_cell_lng_${lng}`}>
							Label {lng}
						</TableCell>
					))}
					<TableCell>Type</TableCell>
					<TableCell>
						Global
						<Tooltip
							title={
								<div>
									Global fields are available through your
									details page in the Yanklee app
									<br />
									If required they will be asked to customer
									before starting a workflow
								</div>
							}
						>
							<HelpIcon
								style={{
									marginLeft: '8px',
									marginTop: '-4px',
									verticalAlign: 'middle',
								}}
							/>
						</Tooltip>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{fields.length ? (
					fields.map(f => (
						<TableRow
							key={f.id}
							hover
							onClick={() => editField(f)}
							style={{ cursor: 'pointer' }}
						>
							{/* <TableCell numeric>{f.id}</TableCell> */}
							<TableCell component="th" scope="row">
								{f.slug}
							</TableCell>
							{languages.map(lng => (
								<TableCell key={`field_cell_lng_${lng}`}>
									{f.labels[lng]}
								</TableCell>
							))}
							<TableCell>{f.type}</TableCell>
							<TableCell>
								{f.global ? (
									<CheckIcon
										style={{
											verticalAlign: 'middle',
											marginRight: '8px',
										}}
									/>
								) : (
									'-'
								)}
								{f.required && <Chip label="required" />}
							</TableCell>
						</TableRow>
					))
				) : (
					<TableRow>
						<TableCell colSpan={5} style={{ textAlign: 'center' }}>
							No fields
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	</Paper>
);

export default FieldsList;

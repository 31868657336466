import i18n from '../i18next';
import snackbarActions from './snackbar';

const { errorSnackbar, successSnackbar } = snackbarActions;

export default ({
	action,
	errorMessage, // errorMessage should be mostly sent by server
	finallyCb,
	successCb,
	successMessage,
}) => async dispatch => {
	try {
		const json = await action();
		if (successMessage) {
			dispatch(successSnackbar(successMessage));
		}
		if (successCb) {
			successCb(json);
		}
	} catch (e) {
		console.error('http error', e);
		dispatch(errorSnackbar(e.message || i18n.t('snackbar.anErrorOccured')));
	} finally {
		if (finallyCb) {
			finallyCb();
		}
	}
};

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { DATE_RANGE, fetchChannelStatsAction } from '../../actions/stats';

const TodayMetrics = ({ closedChannelsCount, openedChannelsCount }) => (
	<Paper
		style={{
			backgroundColor: 'rgba(63, 81, 181, 0.8)',
			padding: '8px 0px',
		}}
	>
		<Typography style={{ color: '#fff', marginLeft: '16px' }} variant="h6">
			Today
		</Typography>
		{closedChannelsCount !== null && openedChannelsCount !== null ? (
			<div style={{ display: 'flex' }}>
				<div
					style={{
						alignItems: 'center',
						borderRight: '1px solid #fff',
						display: 'flex',
						flex: 1,
						flexDirection: 'column ',
						padding: '8px',
					}}
				>
					<Typography
						style={{ color: '#fff', marginBottom: '8px' }}
						variant="h5"
					>
						{openedChannelsCount}
					</Typography>
					<Typography style={{ color: '#fff', textAlign: 'center' }}>
						opened channels
					</Typography>
				</div>
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						flex: 1,
						flexDirection: 'column ',
						padding: '8px',
					}}
				>
					<Typography
						style={{ color: '#fff', marginBottom: '8px' }}
						variant="h5"
					>
						{closedChannelsCount}
					</Typography>
					<Typography style={{ color: '#fff', textAlign: 'center' }}>
						closed channels
					</Typography>
				</div>
			</div>
		) : (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress size={20} style={{ color: '#fff' }} />
			</div>
		)}
	</Paper>
);

export default compose(
	connect(({ session: { company } }) => ({
		company,
	})),
	withState('openedChannelsCount', 'setOpenedChannelsCount', null),
	withState('closedChannelsCount', 'setClosedChannelsCount', null),
	lifecycle({
		async componentDidMount() {
			const {
				company: { id: companyId },
				setClosedChannelsCount,
				setOpenedChannelsCount,
			} = this.props;
			const channelStats = await fetchChannelStatsAction(
				companyId,
				DATE_RANGE.DAY
			);
			setClosedChannelsCount(channelStats.closed.current.length);
			setOpenedChannelsCount(channelStats.opened.current.length);
		},
	})
)(TodayMetrics);

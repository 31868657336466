import React from 'react';
import { compose, withHandlers, withState, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import OutlineButton from '../../components/buttons/OutlineButton';

import PhoneHoursSelector from './days-hours-selector/PhoneHoursSelector';
import { DEFAULT_HOURS_TYPE } from './days-hours-selector/DaysHoursSelector';

import { httpAction, sessionActions } from '../../actions';
import { Company } from '../../models';

const PHONE_PRICING = {
	FREE: 'free',
	PAYING: 'paying',
};

const DEFAULT_PHONE_PRICING = PHONE_PRICING.FREE;

const PhoneSettings = ({
	phone,
	phoneHours,
	phonePricing,
	phonePricingError,
	phoneSchedule,
	savePhoneSettings,
	setPhone,
	setPhonePricing,
	t,
	updatePhoneHours,
	updatePhoneSchedule,
}) => (
	<Paper style={{ padding: '16px' }}>
		<Typography variant="title" style={{ marginBottom: '8px' }}>
			{t('phoneSettings')}
		</Typography>
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					minWidth: '50%',
				}}
			>
				<div style={{ marginBottom: '16px' }}>
					<TextField
						id="phone"
						fullWidth
						label={t('phone.label')}
						helperText={t('phone.helpText')}
						margin="normal"
						onChange={({ target: { value } }) => setPhone(value)}
						value={phone}
					/>
				</div>
				<div style={{ marginBottom: '16px' }}>
					<Typography variant="overline">
						<b>{t('phonePricing')}</b>
					</Typography>
					<Typography variant="caption">
						Renseignez le prix d'un appel vers votre service
						téléphonique
					</Typography>
					<FormControlLabel
						control={
							<Switch
								checked={phonePricing === PHONE_PRICING.FREE}
								onChange={({ target: { checked } }) =>
									setPhonePricing(
										checked ? PHONE_PRICING.FREE : ''
									)
								}
							/>
						}
						label="Prix d'un appel local"
					/>
					{phonePricing !== PHONE_PRICING.FREE && (
						<TextField
							autoFocus={!phonePricing}
							id="phonePricing"
							error={!!phonePricingError}
							label={"Prix de l'appel"}
							helperText={phonePricingError || 'Ex: 0,35€/min'}
							margin="dense"
							onChange={({ target: { value } }) =>
								setPhonePricing(value)
							}
							style={{ display: 'block' }}
							value={phonePricing}
						/>
					)}
				</div>
				<div style={{ marginBottom: '16px' }}>
					<PhoneHoursSelector
						phoneHours={phoneHours}
						phoneSchedule={phoneSchedule}
						updatePhoneHours={updatePhoneHours}
						updatePhoneSchedule={updatePhoneSchedule}
					/>
				</div>
				<div style={{ alignSelf: 'flex-end' }}>
					<OutlineButton onClick={savePhoneSettings}>
						{t('save')}
					</OutlineButton>
				</div>
			</div>
		</div>
	</Paper>
);

export default compose(
	translate('y'),
	connect(
		({ session: { company } }) => ({
			company,
		}),
		dispatch =>
			bindActionCreators(
				{
					httpAction,
					setCurrentCompany: sessionActions.setCurrentCompany,
				},
				dispatch
			)
	),
	withState('phone', 'setPhone', ({ company: { phone } }) => phone || ''),
	withState(
		'phonePricing',
		'setPhonePricing',
		({ company: { phonePricing } }) => phonePricing || DEFAULT_PHONE_PRICING
	),
	withState('phonePricingError', 'setPhonePricingError', ''),
	withStateHandlers(
		({ company: { phoneHours, phoneSchedule } }) => ({
			phoneHours: phoneHours || {},
			phoneSchedule: phoneSchedule || DEFAULT_HOURS_TYPE,
		}),
		{
			updatePhoneHours: ({ phoneHours }) => (hours, day) => ({
				phoneHours: Number.isInteger(day)
					? { ...phoneHours, [day]: hours }
					: [...Array(7)].reduce(
							(acc, el, i) => ({
								...acc,
								[i]: hours,
							}),
							{}
					  ),
			}),
			updatePhoneSchedule: (state, { company }) => phoneSchedule => ({
				phoneHours:
					company.phoneSchedule === phoneSchedule
						? company.phoneHours
						: {},
				phoneSchedule,
			}),
		}
	),
	withHandlers({
		savePhoneSettings: ({
			company: { id },
			httpAction,
			phone,
			phonePricing,
			phoneHours,
			phoneSchedule,
			setCurrentCompany,
			setPhonePricingError,
			t,
		}) => async () => {
			if (phone && !phonePricing) {
				setPhonePricingError(t('fields.required'));
			} else {
				setPhonePricingError('');
				httpAction({
					action: () =>
						Company.post({
							data: {
								phone,
								phoneHours,
								phonePricing,
								phoneSchedule,
							},
							id,
							options: {
								method: 'PATCH',
							},
						}),
					successCb: company => setCurrentCompany(company),
					successMessage: 'Paramètres du téléphone sauvegardés',
				});
			}
		},
	})
)(PhoneSettings);

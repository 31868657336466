import React from 'react';
import { compose, lifecycle, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';

import { snackbarActions } from '../../actions';
import { PrimaryYankleeLogo } from '../../components/YankleeLogo';
import { HOME } from '../../routes';

const { REACT_APP_API_BASE_URL } = process.env;

const Verify = ({ isLoading }) => (
	<div
		style={{
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			justifyContent: 'center',
		}}
	>
		<PrimaryYankleeLogo />
		{isLoading && <CircularProgress style={{ margin: 'auto' }} />}
	</div>
);

export default compose(
	translate('y'),
	connect(
		null,
		dispatch =>
			bindActionCreators(
				{
					errorSnackbar: snackbarActions.errorSnackbar,
					successSnackbar: snackbarActions.successSnackbar,
				},
				dispatch
			)
	),
	withState('isLoading', 'setIsLoading', true),
	lifecycle({
		async componentDidMount() {
			const {
				errorSnackbar,
				history,
				match: {
					params: { verificationToken },
				},
				setIsLoading,
				successSnackbar,
				t,
			} = this.props;
			try {
				const res = await fetch(
					`${REACT_APP_API_BASE_URL}/account/verify`,
					{
						body: JSON.stringify({
							verificationToken,
						}),
						headers: {
							'Content-Type': 'application/json',
						},
						method: 'POST',
					}
				);
				if (res.status === 204) {
					successSnackbar(t('snackbar.accountVerification.success'));
				} else {
					errorSnackbar(t('snackbar.accountVerification.error'));
				}
				setIsLoading(false);
			} catch (e) {
				errorSnackbar(t('snackbar.accountVerification.error'));
				console.error(e);
			}
			history.replace(HOME);
		},
	})
)(Verify);

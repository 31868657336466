import {
	CAN_ACTIVE_COMPANY,
	CLEAR_SESSION,
	HAS_INIT_TWILIO,
	SET_CURRENT_COMPANY,
	SET_CURRENT_CHANNEL,
	SET_CHANNELS,
	SET_CURRENT_USER,
	SET_TWILIO_CLIENT,
	SET_FIREBASE_COLLECTION,
	SET_PENDING_WORKFLOW,
} from '../actions/action-types';

const initialState = {
	activable: {
		canActive: false,
	},
	company: null,
	channels: null,
	currentChannel: null,
	firebaseCollection: null,
	hasInitTwilio: false,
	pendingWorkflowTree: null,
	twilioClient: null,
	user: null,
};

export default (state = initialState, { data, type }) => {
	switch (type) {
		case CLEAR_SESSION:
			return initialState;
		case CAN_ACTIVE_COMPANY:
			return {
				...state,
				activable: data,
			};
		case HAS_INIT_TWILIO:
			return {
				...state,
				hasInitTwilio: true,
			};
		case SET_CURRENT_COMPANY:
			return {
				...state,
				company: data,
			};
		case SET_CURRENT_USER:
			return {
				...state,
				user: data,
			};
		case SET_CURRENT_CHANNEL:
			return {
				...state,
				currentChannel: data,
			};
		case SET_CHANNELS:
			return {
				...state,
				channels: data,
			};
		case SET_TWILIO_CLIENT:
			return {
				...state,
				twilioClient: data,
			};
		case SET_FIREBASE_COLLECTION:
			return {
				...state,
				firebaseCollection: data,
			};
		case SET_PENDING_WORKFLOW:
			return {
				...state,
				pendingWorkflow: data,
			};
		default:
			return state;
	}
};

import Cookies from 'js-cookie';

const { REACT_APP_API_BASE_URL } = process.env;

class RootModel {
	constructor({ path }) {
		this.path = path;
	}

	get = async ({ action, id, options = {}, query }) => {
		let path = `${REACT_APP_API_BASE_URL}/api/${this.path}`;
		if (id) {
			path = `${path}/${id}`;
		}
		if (action) {
			path = `${path}/${action}`;
		}
		if (query) {
			path = `${path}/${query}`;
		}
		const headers = options.headers || {};
		const accessToken = Cookies.get('access_token');
		if (accessToken) {
			headers.Authorization = accessToken;
		}
		const res = await fetch(path, { headers });
		const json = await res.json();
		return json;
	};

	post = async ({ action, data, id, nestedId, options = {} }) => {
		const method =
			options.method || ((id && !action) || nestedId ? 'PUT' : 'POST');
		let path = `${REACT_APP_API_BASE_URL}/api/${this.path}`;
		if (id) {
			path = `${path}/${id}`;
		}
		if (action) {
			path = `${path}/${action}`;
		}
		if (nestedId) {
			path = `${path}/${nestedId}`;
		}
		const headers = {
			...options.headers,
			'Content-Type': 'application/json',
		};
		const accessToken = Cookies.get('access_token');
		if (accessToken) {
			headers.Authorization = accessToken;
		}
		try {
			const res = await fetch(path, {
				headers,
				method,
				body: JSON.stringify(data),
			});
			if (res.status === 204) {
				return true;
			}
			const json = await res.json();
			if (!res.ok) {
				console.error(json.error.message);
				throw Error(json.error.message);
			}
			return json;
		} catch (e) {
			console.error('post error', e);
			throw e;
		}
	};
}

export default RootModel;

import React from 'react';
import { compose, withStateHandlers, withPropsOnChange, pure } from 'recompose';
import { translate } from 'react-i18next';

import Switch from '@material-ui/core/Switch';
import { TimePicker } from 'material-ui-pickers';

import { LANGUAGES_MAP } from '../../../i18next';

const formatTime = time => {
	if (!time) return null;
	const ts = time.split(':');
	return new Date().setHours(ts[0], ts[1]);
};

const formatHours = dateTime => {
	const date = new Date(dateTime);
	const hours = date.getHours();
	const minutes = date.getMinutes();
	return `${hours < 10 ? `0${hours}` : hours}:${
		minutes < 10 ? `0${minutes}` : minutes
	}`;
};

const DayHourSelector = ({
	checked,
	day,
	dayIndex,
	dayLabel,
	errors,
	hours = [null, null],
	lng,
	t,
	toggleChecked,
	updateHours,
}) => (
	<div>
		<div style={{ alignItems: 'center', display: 'flex' }}>
			{day && <span style={{ flex: '0 1 20%' }}>{dayLabel}</span>}
			<div style={{ display: 'flex' }}>
				{day && (
					<div style={{ margin: 'auto 16px' }}>
						<Switch checked={checked} onChange={toggleChecked} />
					</div>
				)}
				<div style={{ maxWidth: '100px' }}>
					<TimePicker
						ampm={LANGUAGES_MAP[lng] !== 'fr'}
						disabled={day && !checked}
						helperText={
							errors[0] ? t('fields.required') : t('time.start')
						}
						onChange={time =>
							updateHours([formatHours(time), hours[1]], dayIndex)
						}
						value={formatTime(hours[0])}
					/>
				</div>
				<span style={{ margin: 'auto 24px' }}>{t('time.to')}</span>
				<div style={{ maxWidth: '100px' }}>
					<TimePicker
						ampm={LANGUAGES_MAP[lng] !== 'fr'}
						disabled={day && !checked}
						helperText={
							errors[1] ? t('fields.required') : t('time.end')
						}
						onChange={time =>
							updateHours([hours[0], formatHours(time)], dayIndex)
						}
						value={formatTime(hours[1])}
					/>
				</div>
			</div>
		</div>
	</div>
);

export default compose(
	pure,
	translate('y'),
	withStateHandlers(
		({ day, hours }) => ({
			checked: !!day && !!hours,
		}),
		{
			toggleChecked: ({ checked }, { dayIndex, updateHours }) => () => {
				updateHours([null, null], dayIndex);
				return {
					checked: !checked,
				};
			},
		}
	),
	withPropsOnChange(
		['checked', 'day', 'hours'],
		({ checked, day, hours }) => ({
			errors: [
				!day || (checked && (!hours || !hours[0])),
				!day || (checked && (!hours || !hours[1])),
			],
		})
	),
	pure
)(DayHourSelector);

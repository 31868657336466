import React from 'react';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import Cookies from 'js-cookie';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { sessionActions, snackbarActions } from '../../actions';
import { WhiteOutlineButton } from '../../components/buttons/OutlineButton';
import WhiteTextField from '../../components/text-field/WhiteTextField';
import withPageView from '../../components/withPageView';
import UnloggedWrapper from './UnloggedWrapper';

import { HOME, LOGIN, REQUEST_PASSWORD_RESET, SIGNUP } from '../../routes';

class Login extends React.Component {
	state = {
		isLoading: false,
		mail: '',
		pwd: '',
		errors: {},
	};

	handleFieldChange = (name, value) =>
		this.setState({
			[name]: value,
		});

	onLogin = async e => {
		e.preventDefault();
		this.setState({ isLoading: true });
		const { t } = this.props;
		const { mail, pwd } = this.state;
		const errors = {};
		if (!mail) {
			errors.mail = t('fields.required');
		}
		if (!pwd) {
			errors.pwd = t('fields.required');
		}
		this.setState({ errors });
		if (!Object.keys(errors).length) {
			try {
				await sessionActions.login(mail, pwd);
				this.props.successSnackbar(t('snackbar.loggedIn'));
				this.props.history.push(HOME);
			} catch (e) {
				console.error(e);
				this.props.errorSnackbar(e.message);
			} finally {
				this.setState({ isLoading: false });
			}
		}
	};

	render() {
		if (Cookies.get('access_token')) return <Redirect to={HOME} />;

		const { t } = this.props;
		const { isLoading } = this.state;

		return (
			<UnloggedWrapper>
				<form
					style={{
						display: 'flex',
						flexDirection: 'column',
						margin: 'auto',
						width: '65%',
					}}
				>
					<WhiteTextField
						autoFocus
						margin="normal"
						id="mail"
						label={t('mail')}
						error={!!this.state.errors.mail}
						helperText={this.state.errors.mail || ''}
						value={this.state.mail}
						onChange={({ target: { value } }) =>
							this.handleFieldChange('mail', value)
						}
					/>
					<WhiteTextField
						margin="normal"
						id="pwd"
						label={t('password')}
						type="password"
						error={!!this.state.errors.pwd}
						helperText={this.state.errors.pwd || ''}
						value={this.state.pwd}
						onChange={({ target: { value } }) =>
							this.handleFieldChange('pwd', value)
						}
					/>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							marginTop: '32px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',
							}}
						>
							<Link to={SIGNUP}>
								<Typography style={{ color: 'white' }}>
									{t('signup')}
								</Typography>
							</Link>
							<Link to={REQUEST_PASSWORD_RESET}>
								<Typography style={{ color: 'white' }}>
									{t('passwordForgotten')}
								</Typography>
							</Link>
						</div>
						{isLoading ? (
							<CircularProgress
								size={20}
								style={{ color: '#fff' }}
							/>
						) : (
							<WhiteOutlineButton
								onClick={this.onLogin}
								type="submit"
							>
								{t('login')}
							</WhiteOutlineButton>
						)}
					</div>
				</form>
			</UnloggedWrapper>
		);
	}
}

export default compose(
	translate('y'),
	withPageView(LOGIN),
	connect(
		null,
		dispatch =>
			bindActionCreators(
				{
					errorSnackbar: snackbarActions.errorSnackbar,
					successSnackbar: snackbarActions.successSnackbar,
				},
				dispatch
			)
	)
)(Login);

import React from 'react';
import { translate } from 'react-i18next';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
// import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

import ProfilePicture from './ProfilePicture';

const GlobalSettings = ({ t }) => (
	<Paper style={{ padding: '16px' }}>
		<Typography variant="title" style={{ marginBottom: '8px' }}>
			{t('globalSettings')}
		</Typography>
		<div style={{ display: 'flex' }}>
			<ProfilePicture />
		</div>
	</Paper>
);

export default translate('y')(GlobalSettings);

import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from './action-types';

const openSnackbar = (message, level = 'info') => ({
    data: {
        level,
        message
    },
    type: OPEN_SNACKBAR,
});

export default {
    closeSnackbar: () => ({
        type: CLOSE_SNACKBAR,
    }),
    errorSnackbar: (message) => openSnackbar(message, 'error'),
    infoSnackbar: (message) => openSnackbar(message),
    successSnackbar: (message) => openSnackbar(message, 'success'),
    warningSnackbar: (message) => openSnackbar(message, 'warning'),
};
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// FIXME: DateSeparator will be renamed MessageSeparator in a further release
import DateSeparator from 'anchor-ui/date-separator';
import Message from 'anchor-ui/message';
import MessageList from 'anchor-ui/message-list';

import DateTimeComponent from '../../components/DateTimeComponent';

const styles = {
	messageOwnBox: {
		backgroundColor: '#3f51b5',
	},
};

const ChatMessagesEmptyView = () => (
	<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
		<div style={{ margin: 'auto', textAlign: 'center' }}>
			<Typography variant="display2" style={{ marginBottom: '16px' }}>
				No messages
			</Typography>
			<Typography variant="body">
				You can still send a message to the customer to try to help him
				!
			</Typography>
		</div>
	</div>
);

class ChatMessagesList extends React.Component {
	componentDidMount() {
		if (this.lastEl) {
			// FIXME: small hack to ensure scroll behavior.
			//  To test in production without setTimeout
			setTimeout(() => this.lastEl.scrollToBottom(), 0);
		}
	}

	render() {
		const {
			company: { slug },
			messages,
		} = this.props;
		console.log(messages);

		const nbMessages = messages.length;

		if (!nbMessages) return <ChatMessagesEmptyView />;

		return (
			<MessageList
				autoScroll
				ref={el => {
					this.lastEl = el;
				}}
				style={{ backgroundColor: '#fff', height: '100%' }}
			>
				{messages.map(
					m =>
						m.attributes.type === 'system' ? (
							<DateSeparator key={m.sid} date={m.body} />
						) : (
							<Message
								key={m.sid}
								body={m.body}
								createdAt={
									<DateTimeComponent
										dateTime={m.dateUpdated}
									/>
								}
								//fontSize="medium"
								messageHeaderStyle={{ marginBottom: 0 }}
								messageBodyStyle={{
									display: 'flex',
									flexDirection: 'column',
								}}
								messageTimeStyle={{
									fontSize: '12px',
									marginTop: '6px',
								}}
								myMessage={m.author === slug}
								style={
									m.author === slug
										? {
												backgroundColor: '#3f51b5',
												borderRadius: '16px',
												borderBottomRightRadius: 0,
												padding: '4px 12px',
										  }
										: {
												backgroundColor: '#ddd',
												borderRadius: '16px',
												borderBottomLeftRadius: 0,
												padding: '4px 12px',
										  }
								}
							/>
						)
				)}
			</MessageList>
		);
	}
}

export default withStyles(styles)(ChatMessagesList);

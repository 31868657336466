import React from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SaveIcon from '@material-ui/icons/Save';

import { STEP_TYPES } from '../workflows/utils';
import { DEFAULT_LANGUAGE } from '../../i18next';

const WorkflowStepResultPreview = ({
	result,
	step: { choices, title, type },
}) => (
	<div style={{ display: 'flex' }}>
		<Typography style={{ flex: '1 1 100%' }} variant="overline">
			{title}
		</Typography>
		{type === STEP_TYPES.CHOICES ? (
			<Typography style={{ flex: '1 1 100%' }} variant="overline">
				<b>
					{
						choices.find(({ slug }) => slug === result).labels[
							DEFAULT_LANGUAGE
						]
					}
				</b>
			</Typography>
		) : (
			<div
				style={{ display: 'flex', flex: '1 1 100%', flexWrap: 'wrap' }}
			>
				{result.map(({ labels, slug, value }) => (
					<div
						style={{ display: 'flex', marginRight: '16px' }}
						key={`field_${slug}`}
					>
						<Typography
							style={{ marginRight: '8px' }}
							variant="overline"
						>
							{labels[DEFAULT_LANGUAGE]}:
						</Typography>
						<Typography variant="overline">
							<b>{value || '-'}</b>
						</Typography>
					</div>
				))}
			</div>
		)}
	</div>
);

const WorkflowResultPreview = ({
	workflowResult: { globalFields, result, stepOrder, steps },
}) => (
	<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
		{globalFields && (
			<>
				<Typography variant="caption">Global fields results</Typography>
				{globalFields.map(({ labels, slug, value }) => (
					<div
						style={{ display: 'flex', marginRight: '16px' }}
						key={`global_field_${slug}`}
					>
						<Typography
							style={{ marginRight: '8px' }}
							variant="overline"
						>
							{labels[DEFAULT_LANGUAGE]}:
						</Typography>
						<Typography variant="overline">
							<b>{value}</b>
						</Typography>
					</div>
				))}
			</>
		)}
		<Typography variant="caption">Workflow results</Typography>
		{stepOrder ? (
			stepOrder.map(stepId => (
				<WorkflowStepResultPreview
					key={`workflow_step_preview_${stepId}`}
					result={result[stepId]}
					step={steps[stepId]}
				/>
			))
		) : (
			<Typography>Workflow results are not available</Typography>
		)}
	</div>
);

const LockChannelIcon = withProps(({ locked }) => ({
	Icon: locked ? LockOpenIcon : LockIcon,
}))(({ Icon, locked, toggleLockChannelModal }) => (
	<Tooltip title={locked ? 'Unlock channel' : 'Lock channel'}>
		<Icon
			color="action"
			onClick={e => {
				e.stopPropagation();
				toggleLockChannelModal();
			}}
			style={{
				cursor: 'pointer',
				margin: 'auto',
			}}
		/>
	</Tooltip>
));

const ExpansionPanel = withStyles({
	root: {
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: '1px solid #ddd',
		},
		'&:before': {
			display: 'none',
		},
	},
	expanded: {
		margin: 0,
	},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0,0,0,.03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
		paddingLeft: '16px',
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
	root: {
		padding: theme.spacing.unit * 2,
	},
}))(MuiExpansionPanelDetails);

const ChatDetailsHeader = ({
	channel: {
		attributes: { archived },
	},
	isEditingTitle,
	locked,
	saveTitle,
	title,
	toggleIsEditingTitle,
	toggleLockChannelModal,
	updateTitle,
	workflowResult,
}) => (
	<ExpansionPanel square>
		<ExpansionPanelSummary
			expandIcon={
				<Tooltip title="See details">
					<ExpandMoreIcon />
				</Tooltip>
			}
		>
			<div style={{ alignItems: 'center', display: 'flex', flex: 1 }}>
				<div style={{ alignItems: 'center', display: 'flex', flex: 1 }}>
					{isEditingTitle ? (
						<>
							<Input
								onChange={({ target: { value } }) =>
									updateTitle(value)
								}
								onClick={e => e.stopPropagation()}
								value={title}
								style={{ marginRight: '8px' }}
							/>
							<Tooltip title="Save title">
								<SaveIcon
									color="action"
									onClick={e => {
										e.stopPropagation();
										saveTitle();
									}}
								/>
							</Tooltip>
							<Tooltip title="Cancel edit">
								<CloseIcon
									color="action"
									onClick={e => {
										e.stopPropagation();
										toggleIsEditingTitle();
									}}
								/>
							</Tooltip>
						</>
					) : (
						<>
							<Typography
								style={{ marginRight: '8px' }}
								variant="h6"
							>
								{title}
							</Typography>
							{!archived && (
								<Tooltip title="Edit channel name">
									<EditIcon
										color="action"
										onClick={e => {
											e.stopPropagation();
											toggleIsEditingTitle();
										}}
									/>
								</Tooltip>
							)}
						</>
					)}
				</div>
				{!archived && (
					<LockChannelIcon
						locked={locked}
						toggleLockChannelModal={toggleLockChannelModal}
					/>
				)}
			</div>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<WorkflowResultPreview workflowResult={workflowResult} />
		</ExpansionPanelDetails>
	</ExpansionPanel>
);

export default compose(
	withStateHandlers(
		({ title }) => ({
			isEditingTitle: false,
			title,
		}),
		{
			saveTitle: ({ title }, { channel }) => () => {
				channel.updateFriendlyName(title);
				return {
					isEditingTitle: false,
				};
			},
			toggleIsEditingTitle: ({ isEditingTitle }, { title }) => () => ({
				isEditingTitle: !isEditingTitle,
				title,
			}),
			updateTitle: () => title => ({
				title,
			}),
		}
	)
)(ChatDetailsHeader);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { sessionActions, snackbarActions } from '../../actions';
import { Company, User } from '../../models';
import CompanyForm from './CompanyForm';

class CompanySetup extends React.Component {
	onCreateCompany = async data => {
		try {
			const company = await Company.post({
				data,
			});
			const user = await User.post({
				id: this.props.user.id,
				data: {
					companyId: company.id,
				},
				options: {
					method: 'PATCH',
				},
			});
			this.props.setCurrentUser(user);
			this.props.setCurrentCompany(company);
			this.props.initTwilio(company);
		} catch (e) {
			console.error(e);
			this.props.errorSnackbar(e.message);
		}
	};

	render() {
		return (
			<div style={{ display: 'flex', height: '100%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						margin: 'auto',
						minWidth: '900px',
						width: '50%',
					}}
				>
					<Typography
						variant="display3"
						style={{ color: '#3f51b5', marginBottom: '32px' }}
					>
						Tell us more about your company !
					</Typography>
					<CompanyForm
						mode="create"
						onSubmit={this.onCreateCompany}
					/>
				</div>
			</div>
		);
	}
}

export default connect(
	({ session: { user } }) => ({
		user,
	}),
	dispatch =>
		bindActionCreators(
			{
				initTwilio: sessionActions.initTwilio,
				errorSnackbar: snackbarActions.errorSnackbar,
				setCurrentCompany: sessionActions.setCurrentCompany,
				setCurrentUser: sessionActions.setCurrentUser,
			},
			dispatch
		)
)(CompanySetup);

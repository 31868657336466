import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default Component => ({
	dialogProps: {
		cancelText = 'Cancel',
		content,
		isOpen,
		onClose,
		onSubmit,
		submitText = 'Submit',
		title,
	},
	props,
}) => (
	<>
		<Dialog fullWidth onClose={onClose} open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					{cancelText}
				</Button>
				{onSubmit && (
					<Button onClick={onSubmit} color="primary">
						{submitText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
		<Component {...props} />
	</>
);

import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(() => ({
	cssLabel: {
		color: '#fff !important',
	},
	cssFocused: {
		color: '#fff !important',
	},
	cssOutlinedInput: {
		borderColor: '#fff',
	},
	cssUnderline: {
		color: '#fff',
		'&:hover:before': {
			borderBottom: '2px solid #fff !important',
		},
		'&:before': {
			borderBottomColor: '#fff',
		},
		'&:after': {
			borderBottomColor: '#fff',
		},
	},
}))(
	({
		classes: { cssFocused, cssLabel, cssUnderline },
		InputProps,
		...props
	}) => (
		<TextField
			InputLabelProps={{
				classes: { root: cssLabel },
			}}
			InputProps={{
				classes: {
					focused: cssFocused,
					underline: cssUnderline,
				},
				...InputProps,
			}}
			{...props}
		/>
	)
);

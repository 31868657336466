import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

const ContainedButton = ({
	children,
	classes,
	color = 'primary',
	size = 'small',
	...props
}) => (
	<Button color={color} size={size} variant="contained" {...props}>
		{children}
	</Button>
);

export default withStyles(styles)(ContainedButton);

import React, { Fragment } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FieldsSelector from './FieldsSelector';
import ChoicesCreator from './ChoicesCreator';

import { STEP_TYPES } from '../utils';

const BaseForm = ({
	errors,
	fields,
	updateSlug,
	updateStep,
	step: { id, slug, title, type, choices, fields: stepFields },
}) => (
	<Fragment>
		<TextField
			fullWidth
			margin="dense"
			id="slug"
			label="Slug"
			error={!!errors.slug}
			helperText={errors.slug || 'A unique slug to identify this step'}
			value={slug}
			required
			onChange={({ target: { value } }) => updateSlug(value)}
		/>
		<TextField
			fullWidth
			margin="dense"
			id="title"
			label="Title"
			error={!!errors.title}
			helperText={
				errors.title ||
				'Title of the step that will be displayed to customers'
			}
			value={title}
			required
			onChange={({ target: { value } }) => updateStep('title', value)}
		/>
		<FormGroup row style={{ marginBottom: '4px', marginTop: '8px' }}>
			<FormControlLabel
				control={
					<Radio
						checked={type === STEP_TYPES.FORM}
						onChange={() => updateStep('type', STEP_TYPES.FORM)}
					/>
				}
				disabled={id && type !== STEP_TYPES.FORM}
				label="Form"
			/>
			<FormControlLabel
				control={
					<Radio
						checked={type === STEP_TYPES.CHOICES}
						onChange={() => updateStep('type', STEP_TYPES.CHOICES)}
					/>
				}
				disabled={id && type !== STEP_TYPES.CHOICES}
				label="Choices"
			/>
		</FormGroup>
		{type === STEP_TYPES.FORM && (
			<FieldsSelector
				fields={fields}
				stepFields={stepFields}
				updateStep={updateStep}
			/>
		)}
		{type === STEP_TYPES.CHOICES && (
			<>
				<Typography variant="caption">
					You must add at least two choices
				</Typography>
				<ChoicesCreator choices={choices} updateStep={updateStep} />
			</>
		)}
	</Fragment>
);

export default BaseForm;

import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import i18next, { AVAILABLE_LANGUAGES, LANGUAGES_MAP } from '../../../i18next';
import { isProduction } from '../../../utils';

const styles = theme => ({
	root: {
		margin: 'auto',
		marginRight: 25,
		width: 50,
	},
});

const LanguageSelector = ({ classes, lng }) => (
	<Select
		className={classes.root}
		onChange={({ target: { value } }) => i18next.changeLanguage(value)}
		value={LANGUAGES_MAP[lng]}
	>
		{Object.values(AVAILABLE_LANGUAGES).map((lng, index) => (
			<MenuItem key={index} value={lng}>
				{lng}
			</MenuItem>
		))}
	</Select>
);

export default compose(
	branch(() => isProduction(), renderNothing),
	translate('y'),
	withStyles(styles)
)(LanguageSelector);

import React from 'react';
import { compose, pure } from 'recompose';
import { translate } from 'react-i18next';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

import DayHourSelector from './DayHourSelector';

const HOURS_TYPE = {
	all: 'all',
	select: 'select',
};

export const DEFAULT_HOURS_TYPE = HOURS_TYPE.all;

const WEEK_DAYS = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

const DaysHoursSelector = ({
	hours,
	hoursUpdater,
	info,
	schedule,
	scheduleUpdater,
	t,
	title,
}) => (
	<>
		<Typography variant="overline">
			<b>{title}</b>
		</Typography>
		<Typography variant="caption">{info}</Typography>
		<div>
			<FormControlLabel
				control={
					<Radio
						checked={schedule === HOURS_TYPE.all}
						onChange={() => scheduleUpdater(HOURS_TYPE.all)}
					/>
				}
				label={t('hours.allDays')}
			/>
			<FormControlLabel
				control={
					<Radio
						checked={schedule === HOURS_TYPE.select}
						onChange={() => scheduleUpdater(HOURS_TYPE.select)}
					/>
				}
				label={t('hours.selectDays')}
			/>
		</div>
		{schedule === HOURS_TYPE.all && (
			<DayHourSelector hours={hours[0]} updateHours={hoursUpdater} />
		)}
		{schedule === HOURS_TYPE.select &&
			WEEK_DAYS.map((day, i) => (
				<DayHourSelector
					key={`dayHourSelector_${i}`}
					hours={hours[i]}
					day={day}
					dayIndex={i}
					dayLabel={t(`weekDays.${day}`)}
					updateHours={hoursUpdater}
				/>
			))}
	</>
);

export default compose(
	translate('y'),
	pure
)(DaysHoursSelector);

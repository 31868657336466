import React from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';
import { AutoSizer } from 'react-virtualized';

import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

import 'react-vis/dist/style.css';

import {
	XYPlot,
	// VerticalBarSeries,
	AreaSeries,
	XAxis,
	YAxis,
	HorizontalGridLines,
	VerticalGridLines,
	LineMarkSeries,
	Hint,
} from 'react-vis';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { DATE_RANGE } from '../../../actions/stats';

const ChatStats = ({
	dateRange,
	data,
	hintValue,
	updateHintValue,
	yDomainMax,
}) => (
	<AutoSizer disableHeight>
		{({ width }) => {
			// FIXME: we may want to get 16 padding from theme object when industrializing
			const realWidth = width - 16;
			return (
				<Paper style={{ padding: '8px', width: realWidth }}>
					<XYPlot
						height={500}
						width={realWidth}
						yDomain={[0, yDomainMax]}
					>
						<HorizontalGridLines />
						<VerticalGridLines />
						<XAxis
							tickFormat={x => new Date(x).toLocaleDateString()}
							tickValues={compose(
								dateRange === DATE_RANGE.MONTH
									? data => data.filter((d, i) => i % 5 === 0)
									: data => data,
								data => data.map(({ x }) => x)
							)(data)}
						/>
						<YAxis
							tickFormat={y => y.toFixed()}
							tickValues={[...Array(yDomainMax + 1).keys()]}
							title="Opened channels"
						/>
						<LineMarkSeries
							color="#3f51b5"
							data={data}
							onValueMouseOver={datapoint =>
								updateHintValue(datapoint)
							}
							onValueMouseOut={() => updateHintValue(null)}
							/* fill="style"
                    opacity={1}
                    stroke="#12939a"
                    style={{}} */
						/>
						<AreaSeries color="#3f51b5" data={data} opacity={0.5} />
						{hintValue && (
							<Hint value={hintValue}>
								<Paper style={{ padding: '8px' }}>
									<Typography>
										Day:{' '}
										{new Date(
											hintValue.x
										).toLocaleDateString()}
									</Typography>
									<Typography>
										Incoming requests: {hintValue.y}
									</Typography>
								</Paper>
							</Hint>
						)}
					</XYPlot>
				</Paper>
			);
		}}
	</AutoSizer>
);

const formatChannelsData = (currentOpenedChannels, dateRange) => {
	const date = new Date();
	const data = [];
	// FIXME: put range shift values in constants
	const range = dateRange === DATE_RANGE.MONTH ? 30 : 6;
	for (let i = range; i >= 0; i--) {
		const subDate = subDays(date, i);
		data.push({
			x: startOfDay(subDate).getTime(),
			y: currentOpenedChannels.filter(({ openedAt }) =>
				isSameDay(openedAt, subDate)
			).length,
		});
	}
	return data;
};

export default compose(
	withProps(({ currentOpenedChannels, dateRange }) => ({
		data: formatChannelsData(currentOpenedChannels, dateRange),
	})),
	withProps(({ data }) => ({
		maxOpenedChannelsByDay: Math.max(...data.map(({ y }) => y)),
	})),
	withProps(({ maxOpenedChannelsByDay }) => ({
		yDomainMax: maxOpenedChannelsByDay > 5 ? maxOpenedChannelsByDay : 5,
	})),
	withStateHandlers(
		{
			hintValue: null,
		},
		{
			updateHintValue: () => hintValue => ({
				hintValue,
			}),
		}
	)
)(ChatStats);

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const withFetchHandler = fetchFunctions => Component =>
	class extends React.Component {
		state = {
			hasLoaded: false,
		};

		componentDidMount() {
			this.fetch();
		}

		fetch = async () => {
			const { props } = this;
			const fetchFunctionsKeys = Object.keys(fetchFunctions);
			const datas = await Promise.all(
				fetchFunctionsKeys.map(
					async key => await fetchFunctions[key](props)
				)
			);
			this.setState({
				hasLoaded: true,
				...fetchFunctionsKeys.reduce(
					(acc, key, i) => ({
						...acc,
						[key]: datas[i],
					}),
					{}
				),
			});
		};

		render() {
			const { hasLoaded, ...state } = this.state;
			return hasLoaded ? (
				<Component {...state} {...this.props} />
			) : (
				<CircularProgress />
			);
		}
	};

export default withFetchHandler;

import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from '../actions/action-types';

const initialState = {
	level: 'info',
	message: '',
};

export default (state = initialState, { data, type }) => {
	switch (type) {
		case CLOSE_SNACKBAR:
			return initialState;
		case OPEN_SNACKBAR:
			return {
				...state,
				...data,
			};
		default:
			return state;
	}
};

import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	branch,
	compose,
	renderComponent,
	withHandlers,
	withStateHandlers,
} from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import HelpIcon from '@material-ui/icons/Help';

import { snackbarActions } from '../../../actions';

const withHelpFormStateHandler = withStateHandlers(
	{
		userMessage: '',
	},
	{
		onChangeUserMessage: () => userMessage => ({ userMessage }),
	}
);

const USER_HELP_SLACK_URL =
	'https://hooks.slack.com/services/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';

const withOnSubmitHelpHandler = withHandlers({
	onSubmitHelp: ({
		companyId,
		companySlug,
		errorSnackbar,
		onClose,
		successSnackbar,
		userMessage,
	}) => async () => {
		try {
			await fetch(USER_HELP_SLACK_URL, {
				method: 'POST',
				body: JSON.stringify({
					text: `New message from ${companySlug} (id: ${companyId}):\n ${userMessage}`,
				}),
			});
			successSnackbar('Message sent');
			onClose();
		} catch (e) {
			errorSnackbar('An error occured, please try again');
		}
	},
});

const HelpModal = compose(
	connect(
		({
			session: {
				company: { id: companyId, slug: companySlug },
			},
		}) => ({
			companyId,
			companySlug,
		}),
		dispatch =>
			bindActionCreators(
				{
					errorSnackbar: snackbarActions.errorSnackbar,
					successSnackbar: snackbarActions.successSnackbar,
				},
				dispatch
			)
	),
	withHelpFormStateHandler,
	withOnSubmitHelpHandler
)(({ onClose, onChangeUserMessage, onSubmitHelp, userMessage }) => (
	<Dialog fullWidth onClose={onClose} open>
		<DialogTitle>Tell us what is your problem</DialogTitle>
		<DialogContent>
			<div style={{ marginBottom: '8px' }}>
				<Typography variant="subtitle2">
					We will get back to you by mail very soon
				</Typography>
				<Typography variant="caption">
					You can also chat with us through the Yanklee app
				</Typography>
			</div>
			<TextField
				fullWidth
				label="Your message"
				multiline
				rowsMax="10"
				onChange={({ target: { value } }) => onChangeUserMessage(value)}
				value={userMessage}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary">
				Cancel
			</Button>
			<Button
				onClick={onSubmitHelp}
				color="primary"
				disabled={!userMessage}
			>
				Submit
			</Button>
		</DialogActions>
	</Dialog>
));

const HelpExtendedFab = ({ toggleModal }) => (
	<Fab
		color="primary"
		onClick={toggleModal}
		size="small"
		style={{ boxShadow: 'none' }}
		variant="extended"
	>
		<HelpIcon style={{ marginRight: '8px' }} />
		Get help
	</Fab>
);

const HelpFab = withStyles(
	theme => ({
		helpIcon: {
			cursor: 'pointer',
			'&:hover': {
				color: theme.palette.primary.dark,
			},
		},
	}),
	{ withTheme: true }
)(({ classes: { helpIcon }, toggleModal }) => (
	<HelpIcon className={helpIcon} color="primary" onClick={toggleModal} />
));

const HelpFabForDrawerMenu = branch(
	({ open }) => open,
	renderComponent(HelpExtendedFab)
)(HelpFab);

const HelpWithModal = ({ modalOpened, open, toggleModal }) => (
	<Fragment>
		<HelpFabForDrawerMenu open={open} toggleModal={toggleModal} />
		{modalOpened && <HelpModal onClose={toggleModal} />}
	</Fragment>
);

export default compose(
	withStateHandlers(
		{
			modalOpened: false,
		},
		{
			toggleModal: ({ modalOpened }) => () => ({
				modalOpened: !modalOpened,
			}),
		}
	)
)(HelpWithModal);

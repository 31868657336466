import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { httpAction } from '../../actions';
import { WhiteOutlineButton } from '../../components/buttons/OutlineButton';
import WhiteTextField from '../../components/text-field/WhiteTextField';
import withPageView from '../../components/withPageView';
import UnloggedWrapper from './UnloggedWrapper';

import { HOME, LOGIN, REQUEST_PASSWORD_RESET } from '../../routes';

const { REACT_APP_API_BASE_URL } = process.env;

const RequestPasswordRequest = ({
	errors,
	mail,
	onRequestPasswordReset,
	setMail,
	t,
}) => (
	<UnloggedWrapper>
		<form
			style={{
				display: 'flex',
				flexDirection: 'column',
				margin: 'auto',
				width: '65%',
			}}
		>
			<WhiteTextField
				margin="normal"
				id="mail"
				label={t('mail')}
				error={!!errors.mail}
				helperText={errors.mail || ''}
				value={mail}
				onChange={({ target: { value } }) => setMail(value)}
			/>
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					marginTop: '32px',
				}}
			>
				<Link to={LOGIN}>
					<Typography style={{ color: 'white' }}>
						{t('back')}
					</Typography>
				</Link>
				<div style={{ margin: 'auto', marginRight: 0 }}>
					<WhiteOutlineButton
						onClick={onRequestPasswordReset}
						type="submit"
					>
						{t('submit')}
					</WhiteOutlineButton>
				</div>
			</div>
		</form>
	</UnloggedWrapper>
);

export default compose(
	translate('y'),
	withPageView(REQUEST_PASSWORD_RESET),
	connect(
		null,
		dispatch =>
			bindActionCreators(
				{
					httpAction,
				},
				dispatch
			)
	),
	withState('mail', 'setMail', ''),
	withState('errors', 'setErrors', {}),
	withHandlers({
		onRequestPasswordReset: ({
			errors,
			history,
			httpAction,
			mail,
			setErrors,
			t,
		}) => async e => {
			e.preventDefault();
			if (!mail) {
				errors.mail = t('fields.required');
			}
			setErrors(errors);
			if (!Object.keys(errors).length) {
				httpAction({
					action: () =>
						fetch(
							`${REACT_APP_API_BASE_URL}/account/request-password-reset`,
							{
								body: JSON.stringify({
									email: mail,
								}),
								headers: {
									'Content-Type': 'application/json',
								},
								method: 'POST',
							}
						),
					successCb: () => history.push(HOME),
					successMessage:
						'Instructions to reset your password has been sent to your mail',
				});
			}
		},
	})
)(RequestPasswordRequest);

import React from 'react';

import Typography from '@material-ui/core/Typography';

import YankleeLogo from '../../components/YankleeLogo';

export default ({ children }) => (
	<div
		style={{
			backgroundColor: '#3f51b5',
			display: 'flex',
			height: '100%',
		}}
	>
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				margin: 'auto',
				maxWidth: '500px',
				width: '100%',
			}}
		>
			<div style={{ margin: 'auto' }}>
				<YankleeLogo />
			</div>
			<Typography
				variant="display3"
				style={{ color: 'white', textAlign: 'center' }}
			>
				Yanklee
			</Typography>
			{children}
		</div>
	</div>
);

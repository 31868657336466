import React from 'react';
import { Link } from 'react-router-dom';
import { compose, withProps, withHandlers } from 'recompose';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import OutlineButton from '../../../components/buttons/OutlineButton';
import { FIELDS } from '../../../routes';

const FieldsSelector = ({
	fields,
	notSelectedFields,
	selectedFields,
	toggleRequiredField,
	toggleSelectedField,
}) => (
	<div style={{ display: 'flex' }}>
		<div
			style={{
				borderRight: '1px solid #ddd',
				marginRight: '8px',
				width: '50%',
			}}
		>
			<Typography variant="subheading">Selected fields</Typography>
			{selectedFields.length ? (
				selectedFields.map(f => (
					<div
						key={`selected_fields_${f.id}`}
						style={{ display: 'flex' }}
					>
						<Chip
							label={f.slug}
							onDelete={() => toggleSelectedField(f)}
							style={{ margin: '4px' }}
						/>
						<div
							style={{
								margin: 'auto',
								marginRight: '16px',
								marginTop: '-4px',
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={f.required}
										onChange={({ target: { checked } }) =>
											toggleRequiredField(f, checked)
										}
									/>
								}
								label="Required"
								style={{ marginLeft: '16px' }}
							/>
						</div>
					</div>
				))
			) : (
				<Typography style={{ margin: '8px' }}>
					<i>Add a least a field to create a step</i>
				</Typography>
			)}
		</div>
		<div style={{ flexGrow: 1 }}>
			<Typography variant="subheading">Your fields</Typography>
			{notSelectedFields.length ? (
				notSelectedFields.map(f => (
					<div key={`not_selected_fields_${f.id}`}>
						<Chip
							label={f.slug}
							deleteIcon={<AddIcon />}
							onDelete={() => toggleSelectedField(f)}
							style={{ margin: '4px' }}
						/>
					</div>
				))
			) : (
				<div style={{ textAlign: 'center' }}>
					<Typography
						style={{
							margin: '8px',
							marginBottom: '16px',
						}}
					>
						<i>
							{fields.length
								? 'All available fields have been added to this step'
								: 'You have no fields'}
						</i>
					</Typography>
					<Link to={FIELDS}>
						<OutlineButton>Manage fields</OutlineButton>
					</Link>
				</div>
			)}
		</div>
	</div>
);

export default compose(
	withProps(({ fields, stepFields = [] }) => ({
		fields: fields.filter(({ global, required }) => !(global && required)),
		selectedFields: stepFields,
	})),
	withProps(({ fields, selectedFields }) => ({
		notSelectedFields: fields.filter(
			f => selectedFields.findIndex(sf => sf.id === f.id) === -1
		),
	})),
	withHandlers({
		toggleSelectedField: ({ selectedFields, updateStep }) => field => {
			const idx = selectedFields.findIndex(sf => sf.id === field.id);
			if (idx !== -1) {
				selectedFields.splice(idx, 1);
			} else {
				selectedFields.push({
					...field,
					required: false,
				});
			}
			updateStep('fields', selectedFields);
		},
		toggleRequiredField: ({ stepFields, updateStep }) => (
			field,
			checked
		) => {
			updateStep(
				'fields',
				stepFields.map(({ required, ...stepField }) => ({
					...stepField,
					required: stepField.id === field.id ? checked : required,
				}))
			);
		},
	})
)(FieldsSelector);

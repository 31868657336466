import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import fr from './translations/fr.json';
import en from './translations/en.json';

import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';

export const AVAILABLE_LANGUAGES = {
	ENGLISH: 'en',
	FRENCH: 'fr',
};

export const LANGUAGES_MAP = {
	en: AVAILABLE_LANGUAGES.ENGLISH,
	'en-US': AVAILABLE_LANGUAGES.ENGLISH,
	fr: AVAILABLE_LANGUAGES.FRENCH,
	'fr-FR': AVAILABLE_LANGUAGES.FRENCH,
};

export const LOCALE_MAP = {
	en: enLocale,
	'en-US': enLocale,
	fr: frLocale,
	'fr-FR': frLocale,
};

i18n.use(LanguageDetector)
	.use(reactI18nextModule) // if not using I18nextProvider
	.init({
		debug: true,
		defaultNS: 'y',
		resources: {
			en,
			fr,
		},

		// see https://www.i18next.com/principles/fallback for more details
		fallbackLng: AVAILABLE_LANGUAGES.ENGLISH,

		interpolation: {
			escapeValue: false, // not needed for react!!
		},

		// react i18next special options (optional)
		react: {
			wait: true,
			/* bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default' */
		},
	});

export const DEFAULT_LANGUAGE = LANGUAGES_MAP[i18n.languages[0]];

export default i18n;

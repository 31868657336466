import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const OutlineButton = withStyles(
    theme => ({
        outline: {
            borderColor: theme.palette.primary.main
        }
    })
)(
    ({ children, className, classes, color = 'primary', size = 'small', ...props }) => (
        <Button
            className={classNames(classes.outline, className)}
            color={color}
            size={size}
            variant="outlined"
            {...props}
        >
            {children}
        </Button>
    )
);

export const WhiteOutlineButton = withStyles(
    theme => ({
        white: {
            borderColor: 'white',
            color: 'white',
        }
    })
)(
    ({ children, classes, ...props }) => (
        <OutlineButton
            className={classes.white}
            {...props}
        >
            {children}
        </OutlineButton>
    )
);

export default OutlineButton;
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { User } from '../../models';
import { sessionActions, snackbarActions } from '../../actions';
import { WhiteOutlineButton } from '../../components/buttons/OutlineButton';
import WhiteTextField from '../../components/text-field/WhiteTextField';
import withPageView from '../../components/withPageView';
import UnloggedWrapper from './UnloggedWrapper';

import { HOME, LOGIN, SIGNUP } from '../../routes';

class Signup extends React.Component {
	state = {
		mail: '',
		isLoading: false,
		pwd: '',
		showPassword: false,
		errors: {},
	};

	togglePasswordVisibility = () =>
		this.setState(({ showPassword }) => ({
			showPassword: !showPassword,
		}));

	handleChange = (name, value) =>
		this.setState({
			[name]: value,
		});

	onCreateAccount = async e => {
		e.preventDefault();
		this.setState({ isLoading: true });
		const { t } = this.props;
		const { mail, pwd } = this.state;
		const errors = {};
		if (!mail) {
			errors.mail = t('fields.required');
		}
		if (!pwd) {
			errors.pwd = t('fields.required');
		} else if (pwd.length < 6) {
			errors.pwd = t('fields.password.length');
		}
		this.setState({ errors });
		if (!Object.keys(errors).length) {
			try {
				await User.post({
					data: {
						email: mail,
						password: pwd,
					},
				});
				await sessionActions.login(mail, pwd);
				this.props.successSnackbar(t('snackbar.accountCreated'));
				this.props.history.push(HOME);
			} catch (e) {
				console.error(e);
				this.props.errorSnackbar(e.message);
			} finally {
				this.setState({ isLoading: false });
			}
		}
	};

	render() {
		const { t } = this.props;
		const { isLoading } = this.state;
		return (
			<UnloggedWrapper>
				<form
					style={{
						display: 'flex',
						flexDirection: 'column',
						margin: 'auto',
						width: '65%',
					}}
				>
					<WhiteTextField
						autoFocus
						margin="normal"
						id="mail"
						label={t('mail')}
						error={!!this.state.errors.mail}
						helperText={this.state.errors.mail || ''}
						value={this.state.mail}
						onChange={({ target: { value } }) =>
							this.handleChange('mail', value)
						}
					/>
					<WhiteTextField
						margin="normal"
						id="pwd"
						label={t('password')}
						type={this.state.showPassword ? 'text' : 'password'}
						error={!!this.state.errors.pwd}
						helperText={this.state.errors.pwd || ''}
						value={this.state.pwd}
						onChange={({ target: { value } }) =>
							this.handleChange('pwd', value)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={this.togglePasswordVisibility}
										style={{ color: '#fff' }}
									>
										{this.state.showPassword ? (
											<VisibilityIcon />
										) : (
											<VisibilityOffIcon />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							marginTop: '32px',
						}}
					>
						<Link to={LOGIN}>
							<Typography style={{ color: 'white' }}>
								{t('back')}
							</Typography>
						</Link>
						<div style={{ margin: 'auto', marginRight: 0 }}>
							{isLoading ? (
								<CircularProgress
									size={20}
									style={{ color: '#fff' }}
								/>
							) : (
								<WhiteOutlineButton
									onClick={this.onCreateAccount}
									type="submit"
								>
									{t('create')}
								</WhiteOutlineButton>
							)}
						</div>
					</div>
				</form>
			</UnloggedWrapper>
		);
	}
}

export default compose(
	translate('y'),
	withPageView(SIGNUP),
	connect(
		null,
		dispatch =>
			bindActionCreators(
				{
					errorSnackbar: snackbarActions.errorSnackbar,
					successSnackbar: snackbarActions.successSnackbar,
				},
				dispatch
			)
	)
)(Signup);

/* eslint-disable import/first */
require('dotenv').config();
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { translate } from 'react-i18next';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

import App from './App';
import reducers from './reducers';
import './index.css';
// eslint-disable-next-line no-unused-vars
import i18n, { LOCALE_MAP } from './i18next';
import { isProduction } from './utils';

// google analytics init
if (isProduction()) {
	ReactGA.initialize('UA-152618363-1');
	Sentry.init({
		dsn: 'https://5b13290d461e45e1b986d183e718f467@sentry.io/1828092',
	});
}

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#757de8',
			main: '#3f51b5',
			dark: '#002984',
			contrastText: '#fff',
		},
		secondary: {
			light: '#eaa56b',
			main: '#b5763f',
			dark: '#824a14',
			contrastText: '#000',
		},
	},
	status: {
		default: '#8d8d8d', // this is a grey
		error: '#f44336',
		info: '#2196f3',
		success: '#4caf50',
		warning: '#ff9800',
	},
});

const middlewares = [thunk];

if (!isProduction()) {
	const { logger } = require('redux-logger');
	middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));

const LocalizedApp = translate('y')(props => (
	<MuiThemeProvider theme={theme}>
		<MuiPickersUtilsProvider
			utils={DateFnsUtils}
			locale={LOCALE_MAP[props.lng]}
		>
			<App {...props} />
		</MuiPickersUtilsProvider>
	</MuiThemeProvider>
));

const Main = () => (
	<Provider store={store}>
		<BrowserRouter>
			<LocalizedApp />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(<Main />, document.getElementById('root'));

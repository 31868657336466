import { translate } from 'react-i18next';

import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isSameDay from 'date-fns/isSameDay';

import { LOCALE_MAP } from '../i18next';

const formatDateTime = (dateTime, locale) => {
	const now = new Date();
	if (differenceInMinutes(now, dateTime) < 40) {
		return formatDistance(dateTime, now, { locale });
	}
	if (isSameDay(now, dateTime)) {
		return format(dateTime, 'p', { locale });
	}
	return format(dateTime, 'Pp', { locale });
};

const DateTimeComponent = ({ dateTime, lng }) =>
	formatDateTime(dateTime, LOCALE_MAP[lng]);

export default translate('y')(DateTimeComponent);

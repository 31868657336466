import React from 'react';
import {
	compose,
	lifecycle,
	withHandlers,
	withState,
	withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ChatStatsChart from './charts/ChatStats';
import KpiCard from './kpi/KpiCard';

import { DATE_RANGE, fetchChannelStatsAction } from '../../actions/stats';
import { LOCALE_MAP } from '../../i18next';

const DATE_RANGE_LABEL = {
	[DATE_RANGE.DAY]: 'Today',
	[DATE_RANGE.WEEK]: 'This week',
	[DATE_RANGE.MONTH]: 'This month',
};

const DEFAULT_DATE_RANGE = DATE_RANGE.WEEK;

const DateRangeChip = compose(
	withStyles({
		chip: {
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.08)',
			},
		},
	}),
	withStateHandlers(
		{
			anchorEl: null,
		},
		{
			setAnchorEl: () => anchorEl => ({
				anchorEl,
			}),
		}
	)
)(
	({
		anchorEl,
		classes: { chip },
		dateRange,
		onChangeDateRange,
		setAnchorEl,
	}) => (
		<>
			<Chip
				className={chip}
				deleteIcon={<KeyboardArrowDownIcon />}
				label={`Date range: ${DATE_RANGE_LABEL[dateRange]}`}
				onDelete={({ target }) => setAnchorEl(target)}
				onClick={({ target }) => setAnchorEl(target)}
				variant="outlined"
			/>
			<Menu
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				open={!!anchorEl}
			>
				{Object.keys(DATE_RANGE)
					.filter(range => DATE_RANGE[range] !== DATE_RANGE.DAY)
					.map(range => (
						<MenuItem
							key={`range_selector_${DATE_RANGE[range]}`}
							onClick={() => {
								onChangeDateRange(DATE_RANGE[range]);
								setAnchorEl(null);
							}}
						>
							{DATE_RANGE_LABEL[DATE_RANGE[range]]}
						</MenuItem>
					))}
			</Menu>
		</>
	)
);

const Dashboard = ({ channels, dateRange, fetchChannelStatsHandler, lng }) => (
	<div>
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				marginBottom: '8px',
			}}
		>
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					flex: 1,
				}}
			>
				<Typography style={{ marginRight: '16px' }} variant="h5">
					Reports
				</Typography>
				{dateRange && (
					<Typography style={{ marginTop: '6px' }} variant="caption">
						{format(
							(dateRange === DATE_RANGE.WEEK
								? startOfWeek
								: startOfMonth)(new Date()),
							'P',
							{
								locale: LOCALE_MAP[lng],
							}
						)}{' '}
						-{' '}
						{format(new Date(), 'P', {
							locale: LOCALE_MAP[lng],
						})}
					</Typography>
				)}
			</div>
			<DateRangeChip
				dateRange={dateRange}
				onChangeDateRange={fetchChannelStatsHandler}
			/>
		</div>
		{channels ? (
			<>
				<div style={{ display: 'flex', marginBottom: '8px' }}>
					<KpiCard
						data={channels.opened.current.length}
						lastData={channels.opened.last.length}
						title="Opened channels"
					/>
					<KpiCard
						data={channels.closed.current.length}
						lastData={channels.closed.last.length}
						title="Closed channels"
					/>
					<KpiCard
						data={20}
						lastData={0}
						evolution="+7.5%"
						title="Mean message count"
					/>
					<KpiCard
						data={20}
						lastData={0}
						evolution="+7.5%"
						title="Mean resolution time"
					/>
				</div>
				<ChatStatsChart
					currentOpenedChannels={channels.opened.current}
					dateRange={dateRange}
				/>
			</>
		) : (
			<CircularProgress />
		)}
	</div>
);

export default compose(
	translate('y'),
	connect(({ session: { company } }) => ({
		company,
	})),
	withState('channels', 'setChannels', null),
	withState('dateRange', 'setDateRange', null),
	withHandlers({
		fetchChannelStatsHandler: ({
			company: { id: companyId },
			dateRange,
			setChannels,
			setDateRange,
		}) => async newDateRange => {
			if (dateRange !== newDateRange) {
				setChannels(null);
				setDateRange(newDateRange);
				setChannels(
					await fetchChannelStatsAction(companyId, newDateRange)
				);
			}
		},
	}),
	lifecycle({
		componentDidMount() {
			const { fetchChannelStatsHandler } = this.props;
			fetchChannelStatsHandler(DEFAULT_DATE_RANGE);
		},
	})
)(Dashboard);

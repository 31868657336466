import React from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';

import ChatListCompany from './ChatListCompany';
import ChatDetailsView from './ChatDetailsView';

import { sessionActions } from '../../actions';
import withPageView from '../../components/withPageView';

import { CHAT } from '../../routes';

class ChatContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingChannels: false,
			hasFetchChannels: !!props.channels,
		};
	}

	componentDidMount() {
		this.setState({ isLoadingChannels: true });
		this.getChannels();
	}

	/* fetchChannelsSid = async () => {
		const snapshot = await this.props.firebaseCollection
			.orderBy('created_at', 'desc')
			.limit(10)
			.get();
		const channelsSid = [];
		snapshot.forEach(doc => {
			console.log(doc.id, doc.data());
			channelsSid.push(doc.id);
		});
		return channelsSid;
	}; */

	// TODO: remove listeners on unmount
	addChannelListeners = () => {
		const { client } = this.props;
		client.on('messageAdded', message => {
			const { channel } = message;
			const {
				channels,
				currentChannel,
				company: { slug },
				setChannels,
			} = this.props;
			setChannels({
				...channels,
				[channel.sid]: {
					channel,
					isMember: channels[channel.sid].isMember,
					messagesUnreadCount:
						message.author === slug ||
						(currentChannel && channel.sid === currentChannel.sid)
							? 0
							: channels[channel.sid].messagesUnreadCount + 1,
				},
			});
		});
		client.on('channelAdded', channel => {
			const { channels, setChannels } = this.props;
			setChannels({
				...channels,
				[channel.sid]: {
					channel,
					isMember: false,
					messagesUnreadCount: 0,
				},
			});
		});
		client.on('channelJoined', channel => {
			const { channels, setChannels } = this.props;
			setChannels({
				...channels,
				[channel.sid]: {
					channel,
					isMember: true,
					messagesUnreadCount: 0,
				},
			});
		});
		client.on('channelUpdated', ({ channel, updateReasons }) => {
			const { channels, setChannels } = this.props;
			setChannels({
				...channels,
				[channel.sid]: {
					...channels[channel.sid],
					channel,
				},
			});
		});
	};

	getChannels = async () => {
		this.setState({ startFetchChannels: true });
		const { client } = this.props;
		// const channelsSid = await this.fetchChannelsSid();
		/* const paginator = await Promise.all(
			channelsSid.map(sid => client.getChannelBySid(sid))
		); */
		const paginator = await client.getSubscribedChannels(/* { limit: 10 } */);
		const channels = paginator.items;
		// FIXME: small hack so channels members attr is populate by obscur twilio code
		// should try the timeout on various devices & network quality in prod to ensure behaviour
		await new Promise(res => setTimeout(res, 500));
		const newChannels = {};
		for (const c of channels) {
			const isMember = c.members.size === 2;
			let count = 0;
			if (isMember) {
				count = await c.getUnconsumedMessagesCount();
			}
			newChannels[c.sid] = {
				channel: c,
				isMember,
				messagesUnreadCount: count,
			};
		}
		this.props.setChannels(newChannels);
		this.setState({
			// channels: newChannels,
			isLoadingChannels: false,
			hasFetchChannels: true,
		});
		this.addChannelListeners(client);
	};

	render() {
		const { isLoadingChannels, hasFetchChannels } = this.state;

		if (!hasFetchChannels)
			return (
				<div
					style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<Typography
						variant="display3"
						style={{
							color: '#3f51b5',
							margin: 'auto',
							marginBottom: '16px',
						}}
					>
						Chargement du chat
						<ChatIcon
							style={{ marginLeft: '32px', fontSize: '32px' }}
						/>
					</Typography>
					<CircularProgress
						style={{ margin: 'auto', marginTop: '0px' }}
					/>
				</div>
			);

		const {
			channels,
			company,
			currentChannel,
			setChannels,
			setCurrentChannel,
		} = this.props;

		return (
			<div style={{ display: 'flex', flex: 1 }}>
				<div
					style={{
						borderRight: '1px solid #eee',
						flex: '0 1 25%',
						overflowY: 'auto',
					}}
				>
					<ChatListCompany
						channels={channels}
						currentChannel={currentChannel}
						isLoadingChannels={isLoadingChannels}
						onSelectChannel={channel => setCurrentChannel(channel)}
					/>
				</div>
				<div style={{ display: 'flex', flex: 1 }}>
					<ChatDetailsView
						company={company}
						currentChannel={currentChannel}
						resetUnreadMessagesCount={channel =>
							setChannels({
								...channels,
								[channel.sid]: {
									channel,
									isMember: true,
									messagesUnreadCount: 0,
								},
							})
						}
					/>
				</div>
			</div>
		);
	}
}

const withNoTwilioChatSidView = renderComponent(() => (
	<Paper
		style={{
			alignItems: 'center',
			display: 'flex',
			flex: 1,
			justifyContent: 'center',
			margin: '16px',
			textAlign: 'center',
		}}
	>
		<Typography variant="display1">
			Your account is not validated yet. It should be ok within 24 hours.
			Stay tuned !
		</Typography>
	</Paper>
));

export default compose(
	withPageView(CHAT),
	connect(
		({
			session: {
				channels,
				company,
				currentChannel,
				firebaseCollection,
				twilioClient,
			},
		}) => ({
			channels,
			company,
			currentChannel,
			firebaseCollection,
			client: twilioClient,
		}),
		dispatch =>
			bindActionCreators(
				{
					setChannels: sessionActions.setChannels,
					setCurrentChannel: sessionActions.setCurrentChannel,
				},
				dispatch
			)
	),
	branch(({ client }) => !client, withNoTwilioChatSidView)
)(ChatContainer);

import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import WarningIcon from '@material-ui/icons/Warning';

const LOCK_TYPES = {
	DEFINITIVELY: 'definitively',
	TEMPORARILY: 'temporarily',
};

const LockChannelModal = ({
	locked,
	lockType,
	message,
	notify,
	onClose,
	onSubmit,
	setLockType,
	setMessage,
	toggleNotifyUser,
}) => (
	<Dialog fullWidth onClose={onClose} open>
		<DialogTitle>{locked ? 'Unlock channel' : 'Lock channel'}</DialogTitle>
		<DialogContent>
			<Typography variant="subtitle2">
				{locked
					? 'Unlocking this channel will allow user to make further response'
					: 'Locking this channel will prevent user to make any further respond'}
			</Typography>
			{!locked && (
				<Typography variant="caption">
					You can then find this channel in the locked section and
					unlock it if needed
				</Typography>
			)}
			<RadioGroup
				onChange={({ target: { value } }) => setLockType(value)}
				style={{ flexDirection: 'row' }}
				value={lockType}
			>
				<FormControlLabel
					control={<Radio />}
					label="Temporarily"
					value={LOCK_TYPES.TEMPORARILY}
				/>
				<FormControlLabel
					control={<Radio />}
					label="Definitively"
					value={LOCK_TYPES.DEFINITIVELY}
				/>
			</RadioGroup>
			{lockType === LOCK_TYPES.DEFINITIVELY && (
				<div style={{ alignItems: 'center', display: 'flex' }}>
					<WarningIcon style={{ color: 'red', marginRight: '8px' }} />
					<Typography style={{ color: 'red' }} variant="body1">
						Be careful, the channel will be definitively locked and
						you will not be able to unlock it
					</Typography>
				</div>
			)}
			<Divider style={{ margin: '8px 0' }} />
			<FormControlLabel
				control={
					<Checkbox
						checked={notify}
						onChange={({ target: { checked } }) =>
							toggleNotifyUser(checked)
						}
					/>
				}
				label="Notify user"
			/>
			<TextField
				fullWidth
				label="Message"
				multiline
				helperText="A message to explain"
				value={message}
				onChange={({ target: { value } }) => setMessage(value)}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary">
				Annuler
			</Button>
			<Button onClick={onSubmit} color="primary">
				Valider
			</Button>
		</DialogActions>
	</Dialog>
);

export default compose(
	translate('y'),
	withState('lockType', 'setLockType', LOCK_TYPES.TEMPORARILY),
	withState('notify', 'toggleNotifyUser', true),
	withState('message', 'setMessage', ''),
	withHandlers({
		onSubmit: ({
			archiveChannel,
			lockChannel,
			lockType,
			message,
			notify,
		}) => () => {
			const cb =
				lockType === LOCK_TYPES.DEFINITIVELY
					? archiveChannel
					: lockChannel;
			cb(message, notify);
		},
	})
)(LockChannelModal);

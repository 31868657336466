import React from 'react';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import OutlineButton from '../../components/buttons/OutlineButton';

import ChatHoursSelector from './days-hours-selector/ChatHoursSelector';
import { DEFAULT_HOURS_TYPE } from './days-hours-selector/DaysHoursSelector';

import { httpAction, sessionActions } from '../../actions';
import { Company } from '../../models';

const ChatSettings = ({
	chatDefaultMessage,
	chatHours,
	chatSchedule,
	t,
	saveChatSettings,
	updateChatDefaultMessage,
	updateChatHours,
	updateChatSchedule,
}) => (
	<Paper style={{ padding: '16px' }}>
		<Typography variant="title" style={{ flex: 1, marginBottom: '8px' }}>
			{t('chatSettings')}
		</Typography>
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					minWidth: '50%',
				}}
			>
				<div style={{ marginBottom: '16px' }}>
					<TextField
						id="chatDefaultMessage"
						fullWidth
						label={t('chatForm.defaultMessage.label')}
						helperText={t('chatForm.defaultMessage.helpText')}
						margin="normal"
						multiline
						onChange={({ target: { value } }) =>
							updateChatDefaultMessage(value)
						}
						value={chatDefaultMessage}
					/>
				</div>
				<div style={{ marginBottom: '16px' }}>
					<ChatHoursSelector
						chatHours={chatHours}
						chatSchedule={chatSchedule}
						updateChatHours={updateChatHours}
						updateChatSchedule={updateChatSchedule}
					/>
				</div>
				<div style={{ alignSelf: 'flex-end' }}>
					<OutlineButton onClick={saveChatSettings}>
						{t('save')}
					</OutlineButton>
				</div>
			</div>
		</div>
	</Paper>
);

export default compose(
	translate('y'),
	connect(
		({ session: { company } }) => ({
			company,
		}),
		dispatch =>
			bindActionCreators(
				{
					httpAction,
					setCurrentCompany: sessionActions.setCurrentCompany,
				},
				dispatch
			)
	),
	withStateHandlers(
		({ company: { chatDefaultMessage, chatHours, chatSchedule } }) => ({
			chatDefaultMessage: chatDefaultMessage || '',
			chatHours: chatHours || {},
			chatSchedule: chatSchedule || DEFAULT_HOURS_TYPE,
		}),
		{
			updateChatDefaultMessage: () => chatDefaultMessage => ({
				chatDefaultMessage,
			}),
			updateChatHours: ({ chatHours }) => (hours, day) => ({
				chatHours: Number.isInteger(day)
					? { ...chatHours, [day]: hours }
					: [...Array(7)].reduce(
							(acc, el, i) => ({
								...acc,
								[i]: hours,
							}),
							{}
					  ),
			}),
			updateChatSchedule: (state, { company }) => chatSchedule => ({
				chatHours:
					company.chatSchedule === chatSchedule
						? company.chatHours
						: {},
				chatSchedule,
			}),
		}
	),
	withHandlers({
		saveChatSettings: ({
			chatDefaultMessage,
			chatHours,
			chatSchedule,
			company: { id },
			httpAction,
			setCurrentCompany,
		}) => async () =>
			httpAction({
				action: () =>
					Company.post({
						data: {
							chatDefaultMessage,
							chatHours,
							chatSchedule,
						},
						id,
						options: {
							method: 'PATCH',
						},
					}),
				successCb: company => setCurrentCompany(company),
				successMessage: 'Paramètres du chat sauvegardés',
			}),
	})
)(ChatSettings);

import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import ChatIcon from '@material-ui/icons/ChatBubbleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import AccountManager from './AccountManager';
import DrawerMenu from './DrawerMenu';
//import CompanySelector from './CompanySelector';

import AccountSettingsContainer from '../../account/AccountSettings';
import ChatContainer from '../../chat';
import FieldsContainer from '../../fields';
import HomeContainer from '../../home';
import SettingsContainer from '../../settings';
import WorkflowsContainer from '../../workflows';

import LanguageSelector from './LanguageSelector';

import YankleeLogo from '../../../components/YankleeLogo';
import {
	ACCOUNT,
	CHAT,
	FIELDS,
	HOME,
	SETTINGS,
	WORKFLOWS,
} from '../../../routes';

const menuConf = [
	{
		key: 'home',
		label: 'Home',
		path: HOME,
		icon: HomeIcon,
		component: HomeContainer,
	},
	{
		key: 'chat',
		label: 'Chat',
		path: CHAT,
		icon: ChatIcon,
		component: ChatContainer,
	},
	{
		key: 'workflows',
		label: 'Workflows',
		path: WORKFLOWS,
		icon: AssignmentIcon,
		component: WorkflowsContainer,
	},
	{
		key: 'fields',
		label: 'Fields',
		path: FIELDS,
		icon: TextFieldsIcon,
		component: FieldsContainer,
	},
	{
		key: 'settings',
		label: 'Settings',
		path: SETTINGS,
		icon: SettingsIcon,
		component: SettingsContainer,
	},
];

const styles = theme => ({
	root: {
		flexDirection: 'column',
		height: '100%',
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginLeft: 8,
		marginRight: 8,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		display: 'flex',
		// flexDirection: 'column',
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		overflowX: 'hidden',
		overflowY: 'auto',
	},
});

const PagesRouter = () => (
	<Switch>
		{menuConf.map(({ component, path }, index) => (
			<Route
				key={index}
				exact={path === HOME}
				path={path}
				component={component}
			/>
		))}
		<Route path={ACCOUNT} component={AccountSettingsContainer} />
		<Redirect to={HOME} />
	</Switch>
);

class Layout extends React.Component {
	state = {
		drawerOpen: false,
	};

	toggleDrawerMenu = () => {
		this.setState(ps => ({
			drawerOpen: !ps.drawerOpen,
		}));
	};

	render() {
		const { classes } = this.props;
		const { drawerOpen } = this.state;

		return (
			<div className={classes.root}>
				<AppBar className={classes.appBar} position="static">
					<Toolbar disableGutters>
						<IconButton
							className={classes.menuButton}
							color="inherit"
							aria-label="Menu"
							onClick={this.toggleDrawerMenu}
						>
							{drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
						</IconButton>
						<Link
							to={HOME}
							style={{ alignItems: 'center', display: 'flex' }}
						>
							<div style={{ marginRight: '8px' }}>
								<YankleeLogo
									height={30}
									strokeWidth={25}
									width={30}
								/>
							</div>
							<Typography
								variant="title"
								style={{ color: '#fff', flexGrow: 1 }}
							>
								Yanklee
							</Typography>
						</Link>
						<div
							style={{
								display: 'flex',
								flex: 1,
								justifyContent: 'flex-end',
							}}
						>
							<LanguageSelector />
							{/* <CompanySelector /> */}
							<AccountManager />
						</div>
					</Toolbar>
				</AppBar>
				<div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
					<DrawerMenu conf={menuConf} open={drawerOpen} />
					<main className={classes.content}>
						<PagesRouter />
					</main>
				</div>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Layout);

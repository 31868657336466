import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HelpFeature from './HelpFeature';

const DRAWER_WITDH = 175;

const styles = theme => ({
	item: {
		paddingLeft: '16px',
		paddingRight: '16px',
	},
	itemIcon: {
		marginRight: 0,
	},
	/* itemSelected: {
		backgroundColor: theme.palette.grey[300],
	}, */
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: DRAWER_WITDH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing.unit * 8,
		/* [theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9,
		}, */
	},
	helpIconWrapper: {
		display: 'flex',
		justifyContent: 'center',
		padding: '16px 0',
	},
});

class DrawerMenu extends React.Component {
	getMenuItems = () => {
		const {
			classes: { item, itemIcon },
			conf,
			location: { pathname },
			open,
		} = this.props;
		return conf.map(({ icon: IconComponent, label, path }, index) => (
			<Link key={index} to={path}>
				<ListItem
					button
					className={classNames(item)}
					selected={pathname.split('/')[1] === path.slice(1)}
					style={{
						...(!open && {
							display: 'flex',
							flexDirection: 'column',
						}),
					}}
				>
					<ListItemIcon classes={{ root: itemIcon }}>
						<IconComponent />
					</ListItemIcon>
					<ListItemText
						primary={label}
						primaryTypographyProps={{
							style: {
								...(!open && { fontSize: '12px' }),
							},
						}}
					/>
				</ListItem>
			</Link>
		));
	};

	render() {
		const {
			classes: { drawerPaper, drawerPaperClose, helpIconWrapper },
			open,
		} = this.props;

		return (
			<Drawer
				classes={{
					paper: classNames(drawerPaper, !open && drawerPaperClose),
				}}
				open={open}
				variant="permanent"
			>
				<List disablePadding style={{ flex: 1 }}>
					{this.getMenuItems()}
				</List>
				<div className={helpIconWrapper}>
					<HelpFeature open={open} />
				</div>
			</Drawer>
		);
	}
}

export default compose(
	withRouter,
	withStyles(styles, { withTheme: true })
)(DrawerMenu);

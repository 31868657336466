import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { canDeleteStep, hasEmptySliceWorkflow } from './utils';

const styles = theme => ({
	iconButton: {
		marginBottom: '4px',
	},
	iconButtonLabel: {
		display: 'flex',
		flexDirection: 'column',
	},
	iconButtonRoot: {
		borderRadius: '8px',
		flex: 1,
		'&:not(:last-child)': {
			marginRight: '8px',
		},
	},
	iconStatus: {
		fontSize: '20px',
		marginRight: '4px',
	},
	error: {
		color: theme.status.error,
	},
	success: {
		color: theme.status.success,
	},
});

const WorkflowToolbar = ({
	addStepToWorkflow,
	canDeleteStep,
	canSaveWorkflow,
	classes: {
		error,
		iconButton,
		iconButtonLabel,
		iconButtonRoot,
		iconStatus,
		success,
	},
	hasEmptySlice,
	pendingStep,
	previewStepPath,
	removeStep,
	saveWorkflow,
	selectedStep,
	terminateWorkflow,
}) => (
	<Paper style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
		<div style={{ display: 'flex', marginBottom: '8px' }}>
			<Typography style={{ flex: 1 }} variant="subheading">
				<b>Toolbar</b>
			</Typography>
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
				}}
			>
				{hasEmptySlice ? (
					<ErrorOutlineIcon className={`${iconStatus} ${error}`} />
				) : (
					<CheckCircleOutlineIcon
						className={`${iconStatus} ${success}`}
					/>
				)}
				<Typography variant="caption">
					Workflow {hasEmptySlice && 'not'} valid
				</Typography>
			</div>
		</div>
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex' }}>
				<IconButton
					classes={{
						label: iconButtonLabel,
						root: iconButtonRoot,
					}}
					disabled={!canDeleteStep}
					onClick={removeStep}
				>
					<DeleteIcon className={iconButton} />
					<Typography variant="caption">Delete</Typography>
				</IconButton>
				<IconButton
					classes={{
						label: iconButtonLabel,
						root: iconButtonRoot,
					}}
					disabled={
						!previewStepPath || !!pendingStep || !!selectedStep
					}
					onClick={terminateWorkflow}
				>
					<ChatBubbleOutlineIcon className={iconButton} />
					<Typography variant="caption">Add chat</Typography>
				</IconButton>
				<IconButton
					classes={{
						label: iconButtonLabel,
						root: iconButtonRoot,
					}}
					color="primary"
					disabled={!pendingStep}
					onClick={addStepToWorkflow}
				>
					<AddIcon className={iconButton} />
					<Typography variant="caption">Add step</Typography>
				</IconButton>
				<IconButton
					classes={{
						label: iconButtonLabel,
						root: iconButtonRoot,
					}}
					color="primary"
					disabled={!canSaveWorkflow}
					onClick={saveWorkflow}
				>
					<SaveIcon className={iconButton} />
					<Typography variant="caption">Save</Typography>
				</IconButton>
			</div>
		</div>
	</Paper>
);

export default compose(
	withStyles(styles, { withTheme: true }),
	withProps(
		({
			pendingStep,
			previewStepPath,
			workflow: { tree },
			workflowTree,
		}) => {
			const hasEmptySlice = hasEmptySliceWorkflow(workflowTree);
			return {
				canDeleteStep:
					pendingStep ||
					(previewStepPath &&
						canDeleteStep(workflowTree)(previewStepPath)),
				canSaveWorkflow:
					!hasEmptySlice && tree !== JSON.stringify(workflowTree),
				hasEmptySlice,
			};
		}
	),
	withHandlers({
		removeStep: ({
			removeSelectedStepFromWorkflow,
			selectedStep,
			setPendingStep,
		}) => () =>
			selectedStep
				? removeSelectedStepFromWorkflow()
				: setPendingStep(null),
	})
)(WorkflowToolbar);

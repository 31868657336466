import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { httpAction } from '../../actions';
import { WhiteOutlineButton } from '../../components/buttons/OutlineButton';
import WhiteTextField from '../../components/text-field/WhiteTextField';
import UnloggedWrapper from './UnloggedWrapper';

import { HOME, LOGIN } from '../../routes';

const { REACT_APP_API_BASE_URL } = process.env;

const ResetPassword = ({ errors, onResetPassword, pwd, setPwd, t }) => (
	<UnloggedWrapper>
		<form
			style={{
				display: 'flex',
				flexDirection: 'column',
				margin: 'auto',
				width: '65%',
			}}
		>
			<WhiteTextField
				margin="normal"
				id="pwd"
				label={t('password')}
				error={!!errors.pwd}
				helperText={errors.pwd || ''}
				value={pwd}
				onChange={({ target: { value } }) => setPwd(value)}
			/>
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					marginTop: '32px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Link to={LOGIN}>
						<Typography style={{ color: 'white' }}>
							{t('back')}
						</Typography>
					</Link>
				</div>
				<div style={{ margin: 'auto', marginRight: 0 }}>
					<WhiteOutlineButton onClick={onResetPassword} type="submit">
						{t('submit')}
					</WhiteOutlineButton>
				</div>
			</div>
		</form>
	</UnloggedWrapper>
);

export default compose(
	translate('y'),
	connect(
		null,
		dispatch =>
			bindActionCreators(
				{
					httpAction,
				},
				dispatch
			)
	),
	withState('pwd', 'setPwd', ''),
	withState('errors', 'setErrors', {}),
	withHandlers({
		onResetPassword: ({
			errors,
			history,
			httpAction,
			match: {
				params: { accessToken },
			},
			pwd,
			setErrors,
			t,
		}) => async e => {
			e.preventDefault();
			if (!pwd) {
				errors.pwd = t('fields.required');
			}
			setErrors(errors);
			if (!Object.keys(errors).length) {
				httpAction({
					action: async () => {
						// FIXME: we should factorize with model methods response handle
						try {
							const res = await fetch(
								`${REACT_APP_API_BASE_URL}/account/reset-password`,
								{
									body: JSON.stringify({
										pwd,
									}),
									headers: {
										Authorization: accessToken,
										'Content-Type': 'application/json',
									},
									method: 'POST',
								}
							);
							if (res.status === 204) {
								return true;
							}
							const json = await res.json();
							if (!res.ok) {
								throw Error(json.error.message);
							}
							return json;
						} catch (e) {
							throw e;
						}
					},
					successMessage: 'Password reset',
					finallyCb: () => history.push(HOME),
				});
			}
		},
	})
)(ResetPassword);

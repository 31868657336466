import React from 'react';
import {
	branch,
	compose,
	renderNothing,
	withHandlers,
	withState,
	withStateHandlers,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Cookies from 'js-cookie';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { httpAction } from '../../actions';
import OutlineButton from '../../components/buttons/OutlineButton';
import { User } from '../../models';

const { REACT_APP_API_BASE_URL } = process.env;

const PersonalInformations = ({ email }) => (
	<Paper style={{ marginBottom: '16px', padding: '16px' }}>
		<Typography variant="title" style={{ marginBottom: '8px' }}>
			My informations
		</Typography>
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				margin: 'auto',
				maxWidth: '300px',
			}}
		>
			<TextField disabled margin="normal" label="Email" value={email} />
		</div>
	</Paper>
);

const ChangePassword = compose(
	translate('y'),
	connect(
		null,
		dispatch =>
			bindActionCreators(
				{
					httpAction,
				},
				dispatch
			)
	),
	withStateHandlers(
		{
			newPwd: '',
			newPwdBis: '',
			oldPwd: '',
		},
		{
			resetState: () => () => ({
				newPwd: '',
				newPwdBis: '',
				oldPwd: '',
			}),
			setNewPwd: () => newPwd => ({
				newPwd,
			}),
			setNewPwdBis: () => newPwdBis => ({
				newPwdBis,
			}),
			setOldPwd: () => oldPwd => ({
				oldPwd,
			}),
		}
	),
	withState('errors', 'setErrors', {}),
	withHandlers({
		onChangePassword: ({
			httpAction,
			newPwd,
			newPwdBis,
			oldPwd,
			resetState,
			setErrors,
			t,
		}) => () => {
			const errors = {};
			if (!oldPwd) {
				errors.oldPwd = t('fields.required');
			}
			if (!newPwd) {
				errors.newPwd = t('fields.required');
			} else if (newPwd.length < 6) {
				errors.newPwd = t('fields.password.length');
			}
			if (!newPwdBis) {
				errors.newPwdBis = t('fields.required');
			} else if (newPwd && newPwd !== newPwdBis) {
				errors.newPwdBis = t('fields.password.match');
			}
			setErrors(errors);
			if (!Object.keys(errors).length) {
				httpAction({
					action: () =>
						User.post({
							action: 'change-password',
							data: {
								oldPassword: oldPwd,
								newPassword: newPwd,
							},
						}),
					successCb: () => resetState(),
					successMessage: 'Password changed successfully',
				});
			}
		},
	})
)(
	({
		errors,
		newPwd,
		newPwdBis,
		oldPwd,
		onChangePassword,
		setNewPwd,
		setNewPwdBis,
		setOldPwd,
		t,
	}) => (
		<Paper style={{ padding: '16px' }}>
			<Typography variant="title" style={{ marginBottom: '8px' }}>
				{t('passwordChange')}
			</Typography>
			<form>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						margin: 'auto',
						maxWidth: '300px',
					}}
				>
					<TextField
						margin="normal"
						label={t('oldPassword')}
						type="password"
						error={!!errors.oldPwd}
						helperText={errors.oldPwd || ''}
						value={oldPwd}
						onChange={({ target: { value } }) => setOldPwd(value)}
					/>
					<TextField
						margin="normal"
						label={t('newPassword')}
						type="password"
						error={!!errors.newPwd}
						helperText={errors.newPwd || ''}
						value={newPwd}
						onChange={({ target: { value } }) => setNewPwd(value)}
					/>
					<TextField
						margin="normal"
						label={t('newPasswordBis')}
						type="password"
						error={!!errors.newPwdBis}
						helperText={errors.newPwdBis || ''}
						value={newPwdBis}
						onChange={({ target: { value } }) =>
							setNewPwdBis(value)
						}
					/>
					<OutlineButton
						onClick={onChangePassword}
						style={{ alignSelf: 'flex-end', marginTop: '16px' }}
					>
						{t('confirm')}
					</OutlineButton>
				</div>
			</form>
		</Paper>
	)
);

export const AccountNotVerified = compose(
	translate('y'),
	connect(
		({
			session: {
				user: { emailVerified },
			},
		}) => ({
			emailVerified,
		}),
		dispatch =>
			bindActionCreators(
				{
					httpAction,
				},
				dispatch
			)
	),
	branch(({ emailVerified }) => !!emailVerified, renderNothing),
	withHandlers({
		onSendVerificationMail: ({ httpAction }) => () =>
			httpAction({
				action: () =>
					fetch(
						`${REACT_APP_API_BASE_URL}/account/send-registration-mail`,
						{
							headers: {
								Authorization: Cookies.get('access_token'),
								'Content-Type': 'application/json',
							},
							method: 'POST',
						}
					),
				successMessage: 'A verification mail has been sent to you',
			}),
	})
)(({ onSendVerificationMail }) => (
	<Paper style={{ marginBottom: '16px', padding: '16px' }}>
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				marginBottom: '8px',
			}}
		>
			<ErrorOutlineIcon style={{ color: 'red', marginRight: '8px' }} />
			<Typography variant="h5">
				Your account is not verified yet !
			</Typography>
		</div>
		<Typography>
			We have sent you a mail with a link to verify it. &nbsp;
			<span
				onClick={onSendVerificationMail}
				style={{
					cursor: 'pointer',
					textDecoration: 'underline',
				}}
			>
				Click here to sent a mail again.
			</span>
		</Typography>
	</Paper>
));

const AccountSettings = ({ email, t }) => (
	<div
		style={{
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			padding: '16px',
		}}
	>
		<AccountNotVerified />
		<PersonalInformations email={email} />
		<ChangePassword />
	</div>
);

export default compose(
	translate('y'),
	connect(({ session: { user: { email } } }) => ({
		email,
	}))
)(AccountSettings);

import React from 'react';
import qs from 'qs';

import Spinner from 'react-spinkit';
import MessageInput from 'anchor-ui/message-input';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ChatDetailsHeader from './ChatDetailsHeader';
import ChatMessagesList from './ChatMessagesList';
import LockChannelModal from './LockChannelModal';

// import { lockChannelStat, postNewMessageStat } from '../../actions/stats';

import { ChannelStat, Company, Step, WorkflowResult } from '../../models';

const ChatDetailsEmptyView = () => (
	<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
		<div style={{ margin: 'auto', textAlign: 'center' }}>
			<Typography variant="display2">
				Sélectionnez une conversation
			</Typography>
		</div>
	</div>
);

const initialState = {
	channel: null,
	inputValue: '',
	isTyping: false,
	lockChannelModalOpened: false,
	messages: [],
};

class ChatDetailsView extends React.Component {
	state = initialState;

	componentDidMount() {
		if (this.props.currentChannel) {
			this.fetchChannel();
		}
	}

	componentDidUpdate({ currentChannel }) {
		if (!this.props.currentChannel) {
			this.removeListeners();
			if (this.state.channel) {
				this.setState(initialState);
			}
		} else if (
			(this.props.currentChannel && !currentChannel) ||
			this.props.currentChannel.sid !== currentChannel.sid
		) {
			this.removeListeners();
			this.setState(initialState);
			this.fetchChannel();
		}
	}

	addMessageListener = channel => channel.on('messageAdded', this.addMessage);

	toggleTypingListener = isTyping => this.setState({ isTyping });
	setIsTypingListener = () => this.toggleTypingListener(true);
	removeIsTypingListener = () => this.toggleTypingListener(false);

	addTypingListener = channel => {
		channel.on('typingStarted', this.setIsTypingListener);
		channel.on('typingEnded', this.removeIsTypingListener);
	};

	addMessage = message =>
		this.setState(ps => ({
			messages: [...ps.messages, message],
		}));

	removeListeners = () => {
		const { channel } = this.state;
		if (channel) {
			channel.removeListener('messageAdded', this.addMessage);
			channel.removeListener('typingStarted', this.setIsTypingListener);
			channel.removeListener('typingEnded', this.removeIsTypingListener);
		}
	};

	fetchChannelWorkflowResult = async channelSid => {
		const workflowResult = await WorkflowResult.get({
			query: `findOne?${qs.stringify({
				filter: { where: { twilioChannelSid: channelSid } },
			})}`,
		});
		const steps = await Step.get({
			query: `?${qs.stringify({
				filter: { where: { id: { inq: workflowResult.stepOrder } } },
			})}`,
		});
		const globalFields =
			workflowResult.globalFields &&
			(await Company.get({
				action: 'fields',
				id: this.props.company.id,
				query: `?${qs.stringify({
					filter: {
						where: {
							id: {
								inq: Object.keys(workflowResult.globalFields),
							},
						},
					},
				})}`,
			}));
		return {
			...workflowResult,
			...(workflowResult.globalFields && {
				globalFields: globalFields.map(globalField => ({
					...globalField,
					value: workflowResult.globalFields[globalField.id],
				})),
			}),
			steps: steps.reduce(
				(acc, step) => ({ ...acc, [step.id]: step }),
				{}
			),
		};
	};

	// TODO: we should put fetched messages and channel infos in redux
	fetchChannel = async () => {
		const {
			currentChannel: channel,
			resetUnreadMessagesCount,
		} = this.props;
		// FIXME: improve channel not member detection
		if (channel.members.size !== 2) {
			await channel.join();
		}
		const messages = await channel.getMessages();
		await channel.setAllMessagesConsumed();
		resetUnreadMessagesCount(channel);
		this.addMessageListener(channel);
		this.addTypingListener(channel);

		const workflowResult = await this.fetchChannelWorkflowResult(
			channel.sid
		);
		this.setState({
			channel,
			locked: channel.attributes.locked,
			messages: messages.items,
			workflowResult,
		});
	};

	onInputChange = e => {
		this.setState({ inputValue: e.target.value });
		this.state.channel.typing();
	};

	onSend = async (e, v) => {
		e.preventDefault();
		const { channel, inputValue } = this.state;
		if (inputValue) {
			// FIXME: try...catch important chat actions
			await channel.sendMessage(inputValue);
			this.setState({ inputValue: '' });
			channel.setAllMessagesConsumed();
			channel.updateAttributes({
				...channel.attributes,
				lastMessageTimestamp: Date.now(),
			});
			ChannelStat.post({
				action: 'update-company-message-count',
				data: {
					channelSid: channel.sid,
				},
			});
			// postNewMessageStat(this.props.company.id, channel.sid, inputValue);
		}
	};

	archiveChannel = async (message, notify) => {
		const { channel } = this.state;
		const archivedMessage = 'Channel has been archived';
		await channel.sendMessage(archivedMessage, { type: 'system' });
		if (message) {
			await channel.sendMessage(message);
		}
		await channel.updateAttributes({
			...channel.attributes,
			lastMessageTimestamp: Date.now(),
			archived: true,
		});
		channel.setAllMessagesConsumed();
		this.setState({
			lockChannelModalOpened: false,
		});
		/* if (notify) {
			await lockChannelStat(
				this.props.company.id,
				channel.sid,
				newLocked,
				notify
			);
		} */
	};

	lockChannel = async (message, notify) => {
		const { channel, locked } = this.state;
		const newLocked = !locked;
		const lockedMessage = newLocked
			? 'Channel has been locked'
			: 'Channel has been unlocked';
		await channel.sendMessage(lockedMessage, { type: 'system' });
		if (message) {
			await channel.sendMessage(message);
		}
		await channel.updateAttributes({
			...channel.attributes,
			lastMessageTimestamp: Date.now(),
			locked: newLocked,
		});
		channel.setAllMessagesConsumed();
		this.setState({
			locked: newLocked,
			lockChannelModalOpened: false,
		});
		/* if (notify) {
			await lockChannelStat(
				this.props.company.id,
				channel.sid,
				newLocked,
				notify
			);
		} */
	};

	toggleLockChannelModal = () => {
		this.setState(({ lockChannelModalOpened }) => ({
			lockChannelModalOpened: !lockChannelModalOpened,
		}));
	};

	render() {
		const { company, currentChannel } = this.props;

		if (!currentChannel) return <ChatDetailsEmptyView />;

		const {
			channel,
			globalFields,
			inputValue,
			isTyping,
			locked,
			lockChannelModalOpened,
			messages,
			workflowResult,
		} = this.state;

		if (!channel)
			return (
				<div
					style={{
						backgroundColor: '#fff',
						display: 'flex',
						flex: 1,
					}}
				>
					<CircularProgress style={{ margin: 'auto' }} />
				</div>
			);

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
				}}
			>
				<ChatDetailsHeader
					channel={channel}
					globalFields={globalFields}
					locked={locked}
					toggleLockChannelModal={this.toggleLockChannelModal}
					title={channel.friendlyName}
					workflowResult={workflowResult}
				/>
				{lockChannelModalOpened && (
					<LockChannelModal
						archiveChannel={this.archiveChannel}
						lockChannel={this.lockChannel}
						locked={locked}
						onClose={this.toggleLockChannelModal}
					/>
				)}
				<div style={{ height: '100%' }}>
					<ChatMessagesList company={company} messages={messages} />
				</div>
				{isTyping && (
					<div
						style={{
							backgroundColor: '#ddd',
							borderRadius: '16px',
							borderBottomLeftRadius: 0,
							padding: '8px',
							width: '55px',
							marginBottom: '8px',
							marginLeft: '16px',
						}}
					>
						<Spinner name="three-bounce" />
					</div>
				)}
				{!channel.attributes.archived && (
					<div>
						<MessageInput
							disabled={locked}
							onChange={this.onInputChange}
							placeholder="Ecrivez un message..."
							sendIconColor="#3f51b5"
							sendMessage={this.onSend}
							style={{
								backgroundColor: '#fff',
								border: '1px solid #ddd',
								borderRadius: 0,
								boxShadow: 'none',
								margin: 0,
							}}
							value={inputValue}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default ChatDetailsView;

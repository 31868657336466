import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, withStateHandlers, withHandlers } from 'recompose';
import { translate } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import OutlineButton from '../../../components/buttons/OutlineButton';
import { sessionActions } from '../../../actions';
import { Company } from '../../../models';

const ContactSettings = ({
	contactEmail,
	facebook,
	saveContactSettings,
	t,
	twitter,
	updateCompany,
}) => (
	<Paper style={{ padding: '16px' }}>
		<Typography variant="title" style={{ marginBottom: '8px' }}>
			{t('contactSettings.title')}
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					minWidth: '50%',
				}}
			>
				<div style={{ marginBottom: '16px' }}>
					<TextField
						fullWidth
						margin="normal"
						id="contactEmail"
						label={t('contactSettings.contactEmail.label')}
						// error={!!this.state.errors.slug}
						helperText={t('contactSettings.contactEmail.helpText')}
						value={contactEmail}
						onChange={({ target: { value } }) =>
							updateCompany('contactEmail', value)
						}
					/>
				</div>
				<div style={{ marginBottom: '16px' }}>
					<TextField
						fullWidth
						margin="normal"
						id="facebook"
						label={t('contactSettings.facebook.label')}
						// error={!!this.state.errors.slug}
						helperText={t('contactSettings.facebook.helpText')}
						value={facebook}
						onChange={({ target: { value } }) =>
							updateCompany('facebook', value)
						}
					/>
				</div>
				<div style={{ marginBottom: '16px' }}>
					<TextField
						fullWidth
						margin="normal"
						id="twitter"
						label={t('contactSettings.twitter.label')}
						// error={!!this.state.errors.slug}
						helperText={t('contactSettings.twitter.helpText')}
						value={twitter}
						onChange={({ target: { value } }) =>
							updateCompany('twitter', value)
						}
					/>
				</div>
				<div style={{ alignSelf: 'flex-end' }}>
					<OutlineButton onClick={saveContactSettings}>
						{t('save')}
					</OutlineButton>
				</div>
			</div>
		</div>
	</Paper>
);

export default compose(
	translate('y'),
	connect(
		({ session: { company } }) => ({ company }),
		dispatch =>
			bindActionCreators(
				{
					setCurrentCompany: sessionActions.setCurrentCompany,
				},
				dispatch
			)
	),
	withStateHandlers(
		({ company: { contactEmail, facebook, twitter } }) => ({
			contactEmail: contactEmail || '',
			facebook: facebook || '',
			twitter: twitter || '',
		}),
		{
			updateCompany: () => (key, value) => ({
				[key]: value,
			}),
		}
	),
	withHandlers({
		saveContactSettings: ({
			contactEmail,
			facebook,
			twitter,
			company: { id },
			setCurrentCompany,
		}) => async () => {
			try {
				const company = await Company.post({
					data: {
						contactEmail,
						facebook,
						twitter,
					},
					id,
					options: {
						method: 'PATCH',
					},
				});
				setCurrentCompany(company);
			} catch (e) {
				console.error(e);
			}
		},
	})
)(ContactSettings);

import React, { Fragment } from 'react';

import CompanySettings from '../CompanySettings';
// import PaymentSettings from '../PaymentSettings';

export default () => (
	<Fragment>
		<div style={{ marginBottom: '16px' }}>
			<CompanySettings />
		</div>
		{/* <div>
			<PaymentSettings />
		</div> */}
	</Fragment>
);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, withHandlers, withStateHandlers, withProps } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { httpAction, sessionActions } from '../../../actions';

import OutlineButton from '../../../components/buttons/OutlineButton';

const { REACT_APP_API_BASE_URL } = process.env;

const ProfilePicture = ({
	classes: { imgWrapper },
	company: { name },
	inputRef,
	isUploadingFile,
	profileImage,
	uploadFile,
}) => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		{profileImage ? (
			<img
				alt="yanklee-app-profile"
				className={imgWrapper}
				src={profileImage}
			/>
		) : (
			<div className={imgWrapper}>{name[0]}</div>
		)}
		{isUploadingFile ? (
			<CircularProgress size={20} style={{ margin: 'auto' }} />
		) : (
			<OutlineButton onClick={() => inputRef.current.click()}>
				Upload image
			</OutlineButton>
		)}
		<input
			accept=".png, .jpg, .jpeg"
			onChange={uploadFile}
			ref={inputRef}
			style={{ display: 'none' }}
			type="file"
		/>
	</div>
);

export default compose(
	withStyles(
		theme => ({
			imgWrapper: {
				alignItems: 'center',
				backgroundColor: '#ddd',
				borderRadius: '40px',
				display: 'flex',
				fontSize: '26px',
				height: '80px',
				justifyContent: 'center',
				margin: 'auto',
				marginBottom: '8px',
				width: '80px',
			},
		}),
		{ withTheme: true }
	),
	connect(
		({
			session: {
				company: { profileImage, ...company },
			},
		}) => ({
			company,
			profileImage,
		}),
		dispatch =>
			bindActionCreators(
				{
					httpAction,
					setCurrentCompany: sessionActions.setCurrentCompany,
				},
				dispatch
			)
	),
	withProps(() => ({
		inputRef: React.createRef(),
	})),
	withStateHandlers(
		({ profileImage }) => ({ isUploadingFile: false, profileImage }),
		{
			setIsUploadingFile: () => isUploadingFile => ({ isUploadingFile }),
			updateProfileImage: () => profileImage => ({ profileImage }),
		}
	),
	withHandlers({
		uploadFile: ({
			company,
			httpAction,
			setCurrentCompany,
			setIsUploadingFile,
			updateProfileImage,
		}) => async ({ target: { files } }) => {
			if (files[0]) {
				setIsUploadingFile(true);
				const data = new FormData();
				data.append('file', files[0]);
				data.append('companyId', company.id);
				httpAction({
					action: async () => {
						// FIXME: we should factorize with model methods response handler
						try {
							const res = await fetch(
								`${REACT_APP_API_BASE_URL}/profile-picture`,
								{
									method: 'POST',
									body: data,
								}
							);
							const json = await res.json();
							if (!res.ok) {
								throw Error(json.error.message);
							}
							return json;
						} catch (e) {
							throw e;
						}
					},
					finallyCb: () => setIsUploadingFile(false),
					successCb: ({ profileImage }) => {
						updateProfileImage(profileImage);
						setCurrentCompany({
							...company,
							profileImage,
						});
					},
					successMessage: 'Image uploaded',
				});
			}
		},
	})
)(ProfilePicture);

export const ACCOUNT = '/account';
export const ACCOUNT_RESET_PASSWORD = '/account/reset-password/:accessToken?';
export const ACCOUNT_VERIFY = '/account/verify/:verificationToken?';
export const CHAT = '/chat';
export const FIELDS = '/fields';
export const HOME = '/';
export const LOGIN = '/login';
export const REQUEST_PASSWORD_RESET = '/request-password-reset';
export const SETTINGS = '/settings';
export const SIGNUP = '/signup';
export const WORKFLOWS = '/workflows';

import React from 'react';
import { translate } from 'react-i18next';

import DaysHoursSelector from './DaysHoursSelector';

const ChatHoursSelector = ({
	chatHours,
	chatSchedule,
	t,
	updateChatHours,
	updateChatSchedule,
}) => (
	<DaysHoursSelector
		hours={chatHours}
		hoursUpdater={updateChatHours}
		info={t('chatHours.info')}
		schedule={chatSchedule}
		scheduleUpdater={updateChatSchedule}
		title={t('chatHours.title')}
	/>
);

export default translate('y')(ChatHoursSelector);

import React from 'react';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { snackbarActions } from '../actions';

const styles = theme => ({
	error: {
		backgroundColor: theme.status.error,
	},
	info: {
		backgroundColor: theme.status.info,
	},
	success: {
		backgroundColor: theme.status.success,
	},
	warning: {
		backgroundColor: theme.status.warning,
	},
});

const LevelIcon = ({ level }) => {
	switch (level) {
		case 'error':
			return <ErrorIcon />;
		case 'success':
			return <CheckIcon />;
		case 'warning':
			return <WarningIcon />;
		default:
			return <InfoIcon />;
	}
};

const YankleeSnackbar = ({ classes, closeSnackbar, level, message }) => (
	<Snackbar
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		open={!!message}
		autoHideDuration={5000}
		onClose={closeSnackbar}
		ContentProps={{
			className: classes[level],
		}}
		message={
			<div style={{ display: 'flex' }}>
				<div style={{ marginRight: '8px' }}>
					<LevelIcon level={level} />
				</div>
				<div>
					<div style={{ marginTop: '4px' }}>{message}</div>
				</div>
			</div>
		}
		action={[]}
		transitionDuration={{ exit: 0 }}
	/>
);

export default compose(
	withStyles(styles, { withTheme: true }),
	connect(
		state => ({
			...state.snackbar,
		}),
		dispatch =>
			bindActionCreators(
				{
					closeSnackbar: snackbarActions.closeSnackbar,
				},
				dispatch
			)
	)
)(YankleeSnackbar);

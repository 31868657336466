import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

import YankleeSnackbar from './components/YankleeSnackbar';
import Login from './containers/account/Login';
import RequestPasswordReset from './containers/account/RequestPasswordReset';
import ResetPassword from './containers/account/ResetPassword';
import Signup from './containers/account/Signup';
import AccountVerify from './containers/account/Verify';
import App from './containers/app';

import {
	ACCOUNT_RESET_PASSWORD,
	ACCOUNT_VERIFY,
	LOGIN,
	REQUEST_PASSWORD_RESET,
	SIGNUP,
} from './routes';

const ProtectedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if (Cookies.get('access_token')) {
				return <Component {...rest} />;
			}
			return <Redirect to={LOGIN} />;
		}}
	/>
);

const AppShell = () => (
	<div style={{ height: '100%' }}>
		<YankleeSnackbar />
		<Switch>
			<Route path={LOGIN} component={Login} />
			<Route path={SIGNUP} component={Signup} />
			<Route
				path={REQUEST_PASSWORD_RESET}
				component={RequestPasswordReset}
			/>
			<Route path={ACCOUNT_VERIFY} component={AccountVerify} />
			<Route path={ACCOUNT_RESET_PASSWORD} component={ResetPassword} />
			<ProtectedRoute component={App} />
		</Switch>
	</div>
);

export default AppShell;

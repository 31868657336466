import React from 'react';
import { branch, compose, renderComponent, withProps } from 'recompose';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { DEFAULT_LANGUAGE } from '../../../i18next';

import AddStepView, {
	SelectStepView,
	StartView,
	ValidWorkflowView,
} from './EmptyViews';
import {
	STEP_TYPES,
	computeCurrentStepDepth,
	hasEmptySliceWorkflow,
} from '../utils';

const ChatStepPreview = () => (
	<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
		<div style={{ margin: 'auto', textAlign: 'center' }}>
			<ChatBubbleOutlineIcon
				style={{ fontSize: '64px', marginBottom: '16px' }}
			/>
			<Typography variant="title">
				A chat session will be started
			</Typography>
		</div>
	</div>
);

const ChoicesStepPreview = ({ choices }) => (
	<List>
		{choices.map(({ labels, uid }) => (
			<ListItem divider key={`choice_preview_${uid}`}>
				<ListItemText primary={labels[DEFAULT_LANGUAGE]} />
				<ListItemSecondaryAction>
					<KeyboardArrowRightIcon />
				</ListItemSecondaryAction>
			</ListItem>
		))}
	</List>
);

const FormStepPreview = ({ fields }) => (
	<div
		style={{
			display: 'flex',
			flexDirection: 'column',
			margin: 'auto',
			marginTop: '16px',
			width: '70%',
		}}
	>
		{fields.map(({ id, labels, required, slug }) => (
			<TextField
				disabled
				key={slug}
				id={String(id)}
				label={`${labels[DEFAULT_LANGUAGE]} ${required ? '*' : ''}`}
				margin="dense"
				helperText={''}
			/>
		))}
	</div>
);

const StepPreview = ({ step: { title, type, choices, fields } }) => (
	<div
		style={{
			border: '1px dashed #3f51b5',
			borderRadius: '3px',
			padding: '32px',
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
		}}
	>
		<Typography variant="h4">{title}</Typography>
		{type === STEP_TYPES.CHOICES && (
			<ChoicesStepPreview choices={choices} />
		)}
		{type === STEP_TYPES.FORM && <FormStepPreview fields={fields} />}
		{type === STEP_TYPES.CHAT && <ChatStepPreview />}
	</div>
);

const ComposedStepPreview = compose(
	branch(
		({ workflow, step }) => !Object.keys(workflow).length && !step,
		renderComponent(StartView)
	),
	branch(
		({ hasEmptySlice, step }) => !hasEmptySlice && !step,
		renderComponent(ValidWorkflowView)
	),
	branch(
		({ previewStepPath, step }) => !previewStepPath && !step,
		renderComponent(SelectStepView)
	),
	branch(({ step }) => !step, renderComponent(AddStepView))
)(StepPreview);

const StepPreviewContainer = ({
	currentDepth,
	openStepModal,
	pendingStep,
	selectedStep,
	...props
}) => (
	<Paper
		style={{
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			padding: '8px',
		}}
	>
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				marginBottom: '8px',
			}}
		>
			<Typography style={{ flex: 1 }} variant="subheading">
				<b>Preview</b>
			</Typography>
			{selectedStep &&
				selectedStep.type !== STEP_TYPES.CHAT && (
					<Tooltip title="Edit this step">
						<EditIcon
							onClick={() => openStepModal(selectedStep)}
							style={{ cursor: 'pointer', marginRight: '8px' }}
						/>
					</Tooltip>
				)}
			{currentDepth && (
				<Typography variant="caption">
					Step depth: {currentDepth}
				</Typography>
			)}
		</div>
		<ComposedStepPreview {...props} step={pendingStep || selectedStep} />
	</Paper>
);

export default compose(
	withProps(({ previewStepPath, workflow }) => ({
		currentDepth: computeCurrentStepDepth(previewStepPath),
		hasEmptySlice: hasEmptySliceWorkflow(workflow),
	}))
)(StepPreviewContainer);

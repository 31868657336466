import React from 'react';
import { compose, withProps } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const EvolutionChip = compose(
	withStyles(
		theme => ({
			default: {
				backgroundColor: theme.status.default,
				color: '#fff',
			},
			negative: {
				backgroundColor: theme.status.error,
				color: '#fff',
			},
			positive: {
				backgroundColor: theme.status.success,
				color: '#fff',
			},
		}),
		{ withTheme: true }
	),
	withProps(({ evolution }) => ({
		type: evolution
			? evolution[0] === '+'
				? 'positive'
				: evolution[0] === '-'
					? 'negative'
					: 'default'
			: 'default',
	}))
)(({ classes, evolution, type }) => (
	<Chip className={classes[type]} label={evolution || '-'} />
));

const computeEvolution = (data, lastData) =>
	lastData === 0
		? null
		: `${data > lastData ? '+' : ''}${(
				((data - lastData) / lastData) *
				100
		  ).toString()}%`;

const KpiCard = withProps(({ data, lastData }) => ({
	evolution: computeEvolution(data, lastData),
}))(({ classes: { kpiCard }, data, evolution, title }) => (
	<Paper className={kpiCard}>
		<Typography variant="subtitle1">{title}</Typography>
		<div style={{ alignItems: 'center', display: 'flex' }}>
			<Typography style={{ marginRight: '16px' }} variant="h4">
				{data}
			</Typography>
			<EvolutionChip evolution={evolution} />
		</div>
	</Paper>
));

export default withStyles({
	kpiCard: {
		flex: 1,
		padding: '8px 16px',
		'&:not(:last-child)': {
			marginRight: '8px',
		},
	},
})(KpiCard);

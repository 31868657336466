import React from 'react';

const YankleeLogo = ({
	fill = '#fff',
	height = 200,
	stroke = '#fff',
	strokeWidth = 10,
	width = 200,
}) => (
	<svg height={height} width={width} viewBox="0 0 847 847">
		<g>
			<polygon
				fill={fill}
				points="238,586 347,587 384,504 478,664 499,587 608,587 608,603 512,603 483,705 386,538 357,603 238,602"
				stroke={stroke}
				strokeWidth={strokeWidth}
			/>
			<path
				fill={fill}
				d="M93 434l44 0c13,0 25,4 35,11 -3,-146 93,-299 251,-299 158,0 255,153 252,299 10,-7 22,-11 35,-11l44 0c33,0 61,27 61,61l0 145c0,33 -28,61 -61,61l-44 0c-33,0 -61,-28 -61,-61l0 -145c0,-3 4,-22 4,-22 17,-142 -73,-307 -230,-307 -157,0 -246,165 -229,307 0,0 3,19 3,22l0 145c0,33 -27,61 -60,61l-44 0c-34,0 -61,-28 -61,-61l0 -145c0,-34 27,-61 61,-61zm661 20l-44 0c-22,0 -41,18 -41,41l0 145c0,22 19,41 41,41l44 0c22,0 41,-19 41,-41l0 -145c0,-23 -19,-41 -41,-41zm-617 0l-44 0c-23,0 -41,18 -41,41l0 145c0,22 18,41 41,41l44 0c22,0 40,-19 40,-41l0 -145c0,-23 -18,-41 -40,-41z"
				stroke={stroke}
				strokeWidth={strokeWidth}
			/>
		</g>
	</svg>
);

export const BlackYankleeLogo = () => <YankleeLogo fill="#000" stroke="#000" />;

export const PrimaryYankleeLogo = () => (
	<YankleeLogo fill="#3f51b5" stroke="#3f51b5" />
);

export default YankleeLogo;

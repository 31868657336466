import React from 'react';

import AccountSettings from './account';
import YankleeSettings from './yanklee';

import withPageView from '../../components/withPageView';

import { SETTINGS } from '../../routes';

const SettingsContainer = () => (
	<div style={{ flex: 1, padding: '16px' }}>
		<AccountSettings />
		<YankleeSettings />
	</div>
);

export default withPageView(SETTINGS)(SettingsContainer);

import React from 'react';
import { compose, withProps } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreateIcon from '@material-ui/icons/Create';

const StepManager = ({ canAddStep, openStepModal, setPendingStep, steps }) => (
	<Paper style={{ padding: '8px' }}>
		<Typography variant="subheading">
			<b>Available steps</b>
		</Typography>
		<List>
			<ListItem button divider onClick={() => openStepModal()}>
				<ListItemIcon>
					<AddIcon />
				</ListItemIcon>
				<ListItemText primary="Create a new step" />
			</ListItem>
			{steps.map(s => (
				<ListItem
					key={`step_manager_${s.id}`}
					button
					divider
					onClick={() => openStepModal(s)}
				>
					<ListItemIcon>
						<CreateIcon />
					</ListItemIcon>
					<ListItemText
						primary={s.slug}
						secondary={s.type}
						style={{ wordBreak: 'break-word' }}
					/>
					<ListItemSecondaryAction>
						<Tooltip title="Add to workflow">
							<AddCircleIcon
								color={canAddStep ? 'primary' : 'disabled'}
								onClick={
									canAddStep
										? () => setPendingStep(s)
										: () => {}
								}
								style={{ cursor: 'pointer' }}
							/>
						</Tooltip>
					</ListItemSecondaryAction>
				</ListItem>
			))}
		</List>
	</Paper>
);

export default compose(
	withProps(
		({
			pendingStep,
			previewStepPath,
			selectedStep,
			steps,
			workflowStepsId,
		}) => ({
			canAddStep:
				!pendingStep &&
				(!workflowStepsId.length || (previewStepPath && !selectedStep)),
			steps: steps.filter(step => !workflowStepsId.includes(step.id)),
		})
	)
)(StepManager);

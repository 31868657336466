import React from 'react';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { sessionActions } from '../../actions';

import Layout from './layout';
import CompanySetup from '../account/CompanySetup';

const MainLoader = () => (
	<div style={{ display: 'flex', height: '100%' }}>
		<CircularProgress style={{ margin: 'auto' }} />
	</div>
);

export default compose(
	connect(
		({ session: { company, hasInitTwilio, user } }) => ({
			company,
			hasInitTwilio,
			user,
		}),
		dispatch =>
			bindActionCreators(
				{
					startSession: sessionActions.startSession,
				},
				dispatch
			)
	),
	lifecycle({
		componentDidMount() {
			this.props.startSession();
		},
	}),
	branch(({ user }) => !user, renderComponent(MainLoader)),
	branch(({ company }) => !company, renderComponent(CompanySetup)),
	branch(({ hasInitTwilio }) => !hasInitTwilio, renderComponent(MainLoader))
)(Layout);

import React from 'react';
import { translate } from 'react-i18next';

import DaysHoursSelector from './DaysHoursSelector';

const PhoneHoursSelector = ({
	phoneHours,
	phoneSchedule,
	t,
	updatePhoneHours,
	updatePhoneSchedule,
}) => (
	<DaysHoursSelector
		hours={phoneHours}
		hoursUpdater={updatePhoneHours}
		info={t('chatHours.info')}
		schedule={phoneSchedule}
		scheduleUpdater={updatePhoneSchedule}
		title={t('chatHours.title')}
	/>
);

export default translate('y')(PhoneHoursSelector);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, withHandlers, withState, withStateHandlers } from 'recompose';

import StepCreator from './StepCreator';
import StepPreview from './step-preview';
import StepManager from './StepManager';
import WorkflowTree from './WorkflowTree';
import WorkflowToolbar from './WorkflowToolbar';

import { httpAction, sessionActions } from '../../actions';
import { Company, Workflow } from '../../models';

import withFetchHandler from '../../components/withFetchHandler';

import {
	STEP_TYPES,
	addStepToWorkflow,
	constructExtandedStepKey,
	constructExtandedStepKeysFromTree,
	getStepFromPath,
	makeNextPathFromPendingStep,
	makePreviewPathOnRemoveStep,
	removeStepFromWorkflow,
} from './utils';

const WorkflowCreator = ({
	addStepToWorkflow,
	closeStepModal,
	companyId,
	expandedKeys,
	fields,
	openStepModal,
	pendingStep,
	previewStepPath,
	refreshSteps,
	removeSelectedStepFromWorkflow,
	saveWorkflow,
	selectedStep,
	selectStepFromTree,
	setExpandedKeys,
	setPendingStep,
	stepEdit,
	stepModalOpen,
	steps,
	terminateWorkflow,
	workflow,
	workflowStepsId,
	workflowTree,
}) => (
	<div
		style={{
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		}}
	>
		<div style={{ display: 'flex', flex: 1 }}>
			<div style={{ marginRight: '8px', width: '30%' }}>
				<WorkflowTree
					expandedKeys={expandedKeys}
					onSelectStep={selectStepFromTree}
					previewStepPath={previewStepPath}
					setExpandedKeys={setExpandedKeys}
					steps={steps}
					workflow={workflowTree}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					flex: 1,
					flexDirection: 'column',
					margin: '0 8px',
				}}
			>
				<div style={{ marginBottom: '16px' }}>
					<WorkflowToolbar
						addStepToWorkflow={addStepToWorkflow}
						pendingStep={pendingStep}
						previewStepPath={previewStepPath}
						removeSelectedStepFromWorkflow={
							removeSelectedStepFromWorkflow
						}
						saveWorkflow={saveWorkflow}
						selectedStep={selectedStep}
						setPendingStep={setPendingStep}
						terminateWorkflow={terminateWorkflow}
						workflow={workflow}
						workflowTree={workflowTree}
					/>
				</div>
				<StepPreview
					openStepModal={openStepModal}
					previewStepPath={previewStepPath}
					pendingStep={pendingStep}
					selectedStep={selectedStep}
					workflow={workflowTree}
				/>
			</div>
			<div style={{ marginLeft: '8px', width: '30%' }}>
				<StepManager
					openStepModal={openStepModal}
					pendingStep={pendingStep}
					previewStepPath={previewStepPath}
					selectedStep={selectedStep}
					setPendingStep={setPendingStep}
					steps={steps}
					workflowStepsId={workflowStepsId}
				/>
			</div>
		</div>
		{stepModalOpen && (
			<StepCreator
				companyId={companyId}
				fields={fields}
				onClose={closeStepModal}
				refreshSteps={refreshSteps}
				step={stepEdit}
			/>
		)}
	</div>
);

export default compose(
	connect(
		({
			session: {
				company,
				pendingWorkflow: {
					tree: pendingWorkflowTree,
					stepsId: pendingStepsId,
				} = {},
			},
		}) => ({
			companyId: company.id,
			pendingStepsId,
			pendingWorkflowTree,
		}),
		dispatch =>
			bindActionCreators(
				{
					httpAction,
					setPendingWorkflow: sessionActions.setPendingWorkflow,
				},
				dispatch
			)
	),
	withHandlers({
		fetchSteps: ({ companyId: id }) => () =>
			Company.get({
				action: 'steps',
				id,
			}),
	}),
	withFetchHandler({
		fields: ({ companyId: id }) =>
			Company.get({
				action: 'fields',
				id,
			}),
		steps: ({ fetchSteps }) => fetchSteps(),
		workflow: ({ companyId: id }) =>
			Company.get({
				action: 'workflows',
				id,
			}),
	}),
	withState('steps', 'setSteps', ({ steps }) => steps),
	withStateHandlers(
		{
			stepEdit: undefined,
			stepModalOpen: false,
		},
		{
			openStepModal: () => step => ({
				stepEdit: step,
				stepModalOpen: true,
			}),

			closeStepModal: () => () => ({
				stepEdit: undefined,
				stepModalOpen: false,
			}),
		}
	),
	/*
		expandedKeys: array of steps key in workflow
		pendingStep: step added to preview but not in workflow
		previewStepPath: step path of the current's preview step
		selectedStep: step added to preview from workflow
		workflowStepsId: array of steps id in workflow
	*/
	withStateHandlers(
		({
			pendingStepsId,
			pendingWorkflowTree,
			steps,
			workflow: { id, stepsId = [], tree = '{}' },
		}) => {
			const workflowTree = pendingWorkflowTree || JSON.parse(tree);
			const expandedKeys = constructExtandedStepKeysFromTree(steps)(
				workflowTree
			)();
			return {
				expandedKeys,
				pendingStep: null,
				previewStepPath: '',
				selectedStep: null,
				workflowId: id,
				workflowStepsId: pendingStepsId || stepsId,
				workflowTree,
			};
		},
		{
			addStepToWorkflow: (
				{
					expandedKeys,
					pendingStep,
					previewStepPath,
					workflowStepsId,
					workflowTree,
				},
				{ setPendingWorkflow }
			) => () => {
				const newWorkflowTree = addStepToWorkflow(
					pendingStep,
					workflowTree
				)(previewStepPath);
				const newWorkflowStepsId =
					pendingStep.type === STEP_TYPES.CHAT
						? workflowStepsId
						: [...workflowStepsId, pendingStep.id];
				setPendingWorkflow({
					tree: newWorkflowTree,
					stepsId: newWorkflowStepsId,
				});
				return {
					expandedKeys: [
						...expandedKeys,
						...constructExtandedStepKey(pendingStep)(
							previewStepPath
						),
					],
					previewStepPath: makeNextPathFromPendingStep(pendingStep)(
						previewStepPath
					),
					pendingStep: null,
					selectedStep: null,
					workflowStepsId: newWorkflowStepsId,

					workflowTree: newWorkflowTree,
				};
			},
			removeSelectedStepFromWorkflow: (
				{
					expandedKeys,
					previewStepPath,
					selectedStep,
					workflowStepsId,
					workflowTree,
				},
				{ setPendingWorkflow }
			) => () => {
				const newWorkflowTree = removeStepFromWorkflow(workflowTree)(
					previewStepPath
				);
				const newWorkflowStepsId = workflowStepsId.filter(
					stepId => stepId !== selectedStep.id
				);
				setPendingWorkflow({
					tree: newWorkflowTree,
					stepsId: newWorkflowStepsId,
				});
				return {
					expandedKeys: expandedKeys.filter(
						key => !key.includes(selectedStep.id)
					),
					previewStepPath: makePreviewPathOnRemoveStep(
						previewStepPath
					),
					selectedStep: null,
					workflowStepsId: newWorkflowStepsId,
					workflowTree: newWorkflowTree,
				};
			},
			// FIXME: when selecting already selected node no key is send by library onSelect
			// (actually it acts like an unselect so we need to pass current previewStepPath)
			selectStepFromTree: ({ previewStepPath }, { steps }) => (
				selectedStepPath = previewStepPath
			) => ({
				previewStepPath: selectedStepPath,
				pendingStep: null,
				selectedStep: getStepFromPath(steps)(selectedStepPath),
			}),
			setExpandedKeys: () => expandedKeys => ({
				expandedKeys,
			}),
			setPendingStep: () => pendingStep => ({
				pendingStep,
			}),
			setSelectedStep: () => selectedStep => ({
				selectedStep,
			}),
		}
	),
	withHandlers({
		refreshSteps: ({
			fetchSteps,
			selectedStep,
			setSelectedStep,
			setSteps,
		}) => async () => {
			const steps = await fetchSteps();
			if (selectedStep) {
				setSelectedStep(steps.find(({ id }) => id === selectedStep.id));
			}
			setSteps(steps);
		},
	}),
	withHandlers({
		saveWorkflow: ({
			companyId,
			httpAction,
			workflowId,
			workflowStepsId,
			workflowTree,
		}) => () =>
			httpAction({
				action: () =>
					Workflow.post({
						id: workflowId,
						data: {
							companyId,
							tree: JSON.stringify(workflowTree),
							stepsId: workflowStepsId,
						},
					}),
				successMessage: 'Workflow saved',
			}),
		terminateWorkflow: ({ setPendingStep }) => () =>
			setPendingStep({
				type: STEP_TYPES.CHAT,
			}),
	})
)(WorkflowCreator);

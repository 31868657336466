import qs from 'qs';

import isBefore from 'date-fns/isBefore';
import endOfDay from 'date-fns/endOfDay';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import startOfDay from 'date-fns/startOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';

import { ChannelStat } from '../models';

const { REACT_APP_API_BASE_URL } = process.env;

export const lockChannelStat = async (
	companyId,
	twilioChannelSid,
	locked,
	notify
) => {
	await fetch(`${REACT_APP_API_BASE_URL}/stats/lock-channel`, {
		body: JSON.stringify({
			companyId,
			locked,
			notify,
			twilioChannelSid,
		}),
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
	});
};

export const postNewMessageStat = async (
	companyId,
	twilioChannelSid,
	message
) => {
	await fetch(`${REACT_APP_API_BASE_URL}/stats/new-message/company`, {
		body: JSON.stringify({
			companyId,
			message,
			twilioChannelSid,
		}),
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
	});
};

export const DATE_RANGE = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
};

const computeDateRange = dateRange => {
	const date = new Date();
	switch (dateRange) {
		case DATE_RANGE.DAY:
			return {
				lastRange: [
					startOfDay(subDays(date, 1)),
					endOfDay(subDays(date, 1)),
				],
				currentRange: [startOfDay(date), endOfDay(date)],
			};
		case DATE_RANGE.WEEK:
			return {
				lastRange: [
					startOfWeek(subWeeks(date, 1)),
					endOfWeek(subWeeks(date, 1)),
				],
				currentRange: [startOfWeek(date), endOfDay(date)],
			};
		case DATE_RANGE.MONTH:
			return {
				lastRange: [
					startOfMonth(subMonths(date, 1)),
					endOfMonth(subMonths(date, 1)),
				],
				currentRange: [startOfMonth(date), endOfDay(date)],
			};
		default:
			throw Error('date_range unknown');
	}
};

export const fetchChannelStatsAction = async (companyId, dateRange) => {
	const range = computeDateRange(dateRange);
	const openedChannels = await ChannelStat.get({
		query: `?filter=${JSON.stringify({
			where: {
				companyId,
				openedAt: {
					between: [range.lastRange[0], range.currentRange[1]],
				},
				closedAt: null,
			},
		})}`,
	});
	const closedChannels = await ChannelStat.get({
		query: `?${qs.stringify({
			filter: {
				where: {
					companyId,
					closedAt: {
						between: [range.lastRange[0], range.currentRange[1]],
					},
				},
			},
		})}`,
	});

	const currentOpenedChannels = [];
	const lastOpenedChannels = [];
	for (const openedChannel of openedChannels) {
		if (isBefore(openedChannel.openedAt, range.lastRange[1])) {
			lastOpenedChannels.push(openedChannel);
		} else {
			currentOpenedChannels.push(openedChannel);
		}
	}

	const currentClosedChannels = [];
	const lastClosedChannels = [];
	for (const closedChannel of closedChannels) {
		if (isBefore(closedChannel.closedAt, range.lastRange[1])) {
			lastClosedChannels.push(closedChannel);
		} else {
			currentClosedChannels.push(closedChannel);
		}
	}

	return {
		closed: {
			current: currentClosedChannels,
			last: lastClosedChannels,
		},
		opened: {
			current: currentOpenedChannels,
			last: lastOpenedChannels,
		},
	};
};

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { sessionActions } from '../../actions';
import { Company } from '../../models';
import CompanyForm from '../account/CompanyForm';

class CompanySettings extends React.Component {
	onSaveCompany = async data => {
		try {
			const company = await Company.post({
				data,
				id: this.props.company.id,
			});
			this.props.setCurrentCompany(company);
		} catch (e) {
			console.error(e);
		}
	};

	render() {
		const { company, t } = this.props;

		return (
			<Paper style={{ padding: '16px' }}>
				<Typography variant="title" style={{ marginBottom: '8px' }}>
					{t('companySettings')}
				</Typography>
				{company ? (
					<CompanyForm
						company={company}
						mode="edit"
						onSubmit={this.onSaveCompany}
					/>
				) : (
					<CircularProgress />
				)}
			</Paper>
		);
	}
}

export default connect(
	state => ({
		company: state.session.company,
	}),
	dispatch =>
		bindActionCreators(
			{
				setCurrentCompany: sessionActions.setCurrentCompany,
			},
			dispatch
		)
)(translate('y')(CompanySettings));

import ReactGA from 'react-ga';
import { lifecycle } from 'recompose';

import { isProduction } from '../utils';

export default route =>
	lifecycle({
		componentDidMount() {
			if (isProduction()) {
				ReactGA.pageview(route);
			}
		},
	});

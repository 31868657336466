import React from 'react';
import { compose, withProps, withState } from 'recompose';

import Typography from '@material-ui/core/Typography';

import WorkflowCreator from './WorkflowCreator';

import withDialog from '../../components/withDialog';
import withPageView from '../../components/withPageView';

import { WORKFLOWS } from '../../routes';

const WorkflowsContainer = () => (
	<div
		style={{
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			padding: '16px',
		}}
	>
		<WorkflowCreator />
	</div>
);

const HAS_SEEN_WORKFLOW_EXPLANATION_STORAGE_KEY = 'hasSeenWorkflowExplanation';

export default compose(
	withPageView(WORKFLOWS),
	withState(
		'hasSeenWorkflowExplanation',
		'setHasSeenWorkflowExplanation',
		() => localStorage.getItem(HAS_SEEN_WORKFLOW_EXPLANATION_STORAGE_KEY)
	),
	withProps(
		({ hasSeenWorkflowExplanation, setHasSeenWorkflowExplanation }) => ({
			dialogProps: {
				cancelText: 'Got it',
				content: (
					<>
						<Typography gutterBottom>
							Workflow represent your customer service
						</Typography>
						<Typography gutterBottom>
							Workflow is made of choice and form steps
						</Typography>
						<Typography>
							Create the best path to ease your customers
							experience
						</Typography>
					</>
				),
				isOpen: !hasSeenWorkflowExplanation,
				onClose: () => {
					localStorage.setItem(
						HAS_SEEN_WORKFLOW_EXPLANATION_STORAGE_KEY,
						true
					);
					setHasSeenWorkflowExplanation(true);
				},
				title: 'About workflow',
			},
		})
	),
	withDialog
)(WorkflowsContainer);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import Cookies from 'js-cookie';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { User } from '../../../models';
import { sessionActions } from '../../../actions';
import { ACCOUNT, LOGIN } from '../../../routes';

class AccountManager extends React.Component {
	state = {
		menuAnchor: null,
	};

	logout = async () => {
		try {
			await User.post({
				action: 'logout',
			});
			Cookies.remove('access_token');
			this.setState({ menuAnchor: null });
			this.props.clearSession();
			this.props.history.push(LOGIN);
		} catch (e) {
			console.error(e);
		}
	};

	render() {
		const {
			t,
			user: { emailVerified },
		} = this.props;
		const { menuAnchor } = this.state;

		return (
			<div
				style={{
					cursor: 'pointer',
					marginRight: '16px',
					marginTop: '6px',
				}}
			>
				<AccountCircleIcon
					onClick={e =>
						this.setState({ menuAnchor: e.currentTarget })
					}
				/>
				<Menu
					anchorEl={menuAnchor}
					open={!!menuAnchor}
					onClose={() => this.setState({ menuAnchor: null })}
				>
					<Link
						onClick={() => this.setState({ menuAnchor: null })}
						to={ACCOUNT}
					>
						<MenuItem>
							{t('myAccount')}
							{!emailVerified && (
								<ErrorOutlineIcon
									style={{ color: 'red', marginLeft: '8px' }}
								/>
							)}
						</MenuItem>
					</Link>
					<MenuItem onClick={this.logout}>{t('logout')}</MenuItem>
				</Menu>
			</div>
		);
	}
}

export default compose(
	translate('y'),
	withRouter,
	connect(
		({ session: { user } }) => ({ user }),
		dispatch =>
			bindActionCreators(
				{ clearSession: sessionActions.clearSession },
				dispatch
			)
	)
)(AccountManager);

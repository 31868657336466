import React from 'react';
import { translate } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import OutlineButton from '../../components/buttons/OutlineButton';

const AVAILABLE_LANGUAGES = {
	en: 'English',
	fr: 'French',
};

class CompanyForm extends React.Component {
	constructor(props) {
		super(props);
		const {
			name = '',
			slug = '',
			defaultLanguage = [],
			languages = [],
			countries = ['france'],
		} = props.company || {};
		this.state = {
			name,
			slug,
			defaultLanguage,
			languages,
			countries,
			errors: {},
		};
	}

	handleChange = (name, e) => {
		const { value } = e.target;
		this.setState({
			[name]: value,
		});
	};

	onSubmit = async e => {
		e.preventDefault();
		const { errors, ...data } = this.state;
		// TODO: handle errors
		this.props.onSubmit(data);
	};

	render() {
		const { t } = this.props;

		return (
			<form
				style={{
					display: 'flex',
					flexDirection: 'column',
					margin: 'auto',
					width: '50%',
				}}
			>
				<TextField
					margin="normal"
					id="name"
					label={t('companyForm.name.label')}
					error={!!this.state.errors.name}
					helperText={
						this.state.errors.name || t('companyForm.name.helpText')
					}
					value={this.state.name}
					onChange={e => this.handleChange('name', e)}
				/>
				<TextField
					disabled={this.props.mode === 'edit'}
					margin="normal"
					id="slug"
					label={t('companyForm.slug.label')}
					error={!!this.state.errors.slug}
					helperText={
						this.state.errors.slug || t('companyForm.slug.helpText')
					}
					value={this.state.slug}
					onChange={e => this.handleChange('slug', e)}
				/>
				<FormControl style={{ marginTop: '16px', marginBottom: '8px' }}>
					<InputLabel>
						{t('companyForm.availableLanguages.label')}
					</InputLabel>
					<Select
						onChange={e => this.handleChange('languages', e)}
						multiple
						value={this.state.languages}
					>
						{Object.keys(AVAILABLE_LANGUAGES).map((key, index) => (
							<MenuItem key={`lng_${index}`} value={key}>
								{AVAILABLE_LANGUAGES[key]}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>
						{t('companyForm.availableLanguages.helpText')}
					</FormHelperText>
				</FormControl>
				<FormControl style={{ marginTop: '16px', marginBottom: '8px' }}>
					<InputLabel>
						{t('companyForm.defaultLanguage.label')}
					</InputLabel>
					<Select
						disabled={!this.state.languages.length}
						onChange={e => this.handleChange('defaultLanguage', e)}
						value={this.state.defaultLanguage}
					>
						{this.state.languages.map((key, index) => (
							<MenuItem key={`m_lng_${index}`} value={key}>
								{AVAILABLE_LANGUAGES[key]}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>
						{this.state.languages.length
							? t('companyForm.defaultLanguage.helpText')
							: t('companyForm.defaultLanguage.helpTextEmpty')}
					</FormHelperText>
				</FormControl>
				<FormControl style={{ marginTop: '16px', marginBottom: '8px' }}>
					<InputLabel>{t('companyForm.countries.label')}</InputLabel>
					<Select
						disabled
						multiple
						onChange={e => this.handleChange('countries', e)}
						value={this.state.countries}
					>
						<MenuItem value="france">France</MenuItem>
					</Select>
					<FormHelperText>
						{t('companyForm.countries.helpText')}
					</FormHelperText>
				</FormControl>

				<div
					style={{
						margin: 'auto',
						marginRight: 0,
						marginTop: '32px',
					}}
				>
					<OutlineButton onClick={this.onSubmit} type="submit">
						{t('save')}
					</OutlineButton>
				</div>
			</form>
		);
	}
}

export default translate('y')(CompanyForm);

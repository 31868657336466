import React from 'react';
import { compose, withState, withProps } from 'recompose';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import FieldsList from './FieldsList';
import FieldForm from './FieldForm';

import { Company } from '../../models';
import withDialog from '../../components/withDialog';
import OutlineButton from '../../components/buttons/OutlineButton';
import withPageView from '../../components/withPageView';

import { FIELDS } from '../../routes';

const HAS_SEEN_FIELDS_EXPLANATION_STORAGE_KEY = 'hasSeenFieldsExplanation';

class FieldsContainer extends React.Component {
	state = {
		hasFetchFields: false,
		fields: [],
		fieldEditData: null,
		showFieldForm: false,
	};

	componentDidMount() {
		this.fetchCompanyFields();
	}

	closeFieldForm = () => {
		this.setState({
			fieldEditData: null,
			showFieldForm: false,
		});
	};

	fetchCompanyFields = async () => {
		const {
			company: { id },
		} = this.props;
		if (id) {
			const fields = await Company.get({
				action: 'fields',
				id,
			});
			this.setState({
				hasFetchFields: true,
				fields,
			});
		}
	};

	render() {
		const { company } = this.props;
		const {
			fields,
			fieldEditData,
			hasFetchFields,
			showFieldForm,
		} = this.state;

		return (
			<div style={{ display: 'flex', flex: 1, padding: '16px' }}>
				<div style={{ marginRight: '32px', width: '70%' }}>
					{hasFetchFields ? (
						<FieldsList
							editField={field =>
								this.setState({
									fieldEditData: field,
									showFieldForm: true,
								})
							}
							fields={fields}
							languages={company.languages}
						/>
					) : (
						<CircularProgress />
					)}
				</div>
				{showFieldForm ? (
					<div
						style={{
							borderLeft: '1px solid #ddd',
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
						}}
					>
						<IconButton
							onClick={this.closeFieldForm}
							style={{
								alignSelf: 'flex-end',
								padding: 0,
							}}
						>
							<CloseIcon />
						</IconButton>
						<div
							style={{
								margin: 'auto',
								marginTop: 0,
								width: '80%',
							}}
						>
							<FieldForm
								key={fieldEditData && fieldEditData.id}
								company={company}
								field={fieldEditData || {}}
								fieldsSlug={fields.map(f => f.slug)}
								onSave={() => {
									this.closeFieldForm();
									this.fetchCompanyFields();
								}}
							/>
						</div>
					</div>
				) : (
					<div
						style={{
							borderLeft: '1px solid #ddd',
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							textAlign: 'center',
						}}
					>
						<div
							style={{
								margin: 'auto',
								marginTop: 0,
								width: '85%',
								textAlign: 'center',
							}}
						>
							<Typography style={{ margin: '16px auto' }}>
								You can click on field to edit it or
							</Typography>
							<OutlineButton
								onClick={() =>
									this.setState({ showFieldForm: true })
								}
							>
								Add new field
							</OutlineButton>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default compose(
	withPageView(FIELDS),
	withState('hasSeenFieldsExplanation', 'setHasSeenFieldsExplanation', () =>
		localStorage.getItem(HAS_SEEN_FIELDS_EXPLANATION_STORAGE_KEY)
	),
	withProps(({ hasSeenFieldsExplanation, setHasSeenFieldsExplanation }) => ({
		dialogProps: {
			cancelText: 'Got it',
			content: (
				<>
					<Typography gutterBottom>
						Fields allow you to require informations from your
						customers when they reach you
					</Typography>
					<Typography gutterBottom>
						Global fields will be available in your app settings
						page, if required they will be asked to customers before
						launching a contact session
					</Typography>
					<Typography>
						Fields will be available when creating form steps in
						workflow
					</Typography>
				</>
			),
			isOpen: !hasSeenFieldsExplanation,
			onClose: () => {
				localStorage.setItem(
					HAS_SEEN_FIELDS_EXPLANATION_STORAGE_KEY,
					true
				);
				setHasSeenFieldsExplanation(true);
			},
			title: 'About fields',
		},
	})),
	withDialog,
	connect(({ session: { company } }) => ({
		company,
	}))
)(FieldsContainer);

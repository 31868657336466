import React from 'react';
import { compose, withProps, withState } from 'recompose';

import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LockIcon from '@material-ui/icons/Lock';

import DateTimeComponent from '../../components/DateTimeComponent';

const CHANNEL_TYPES = {
	OPEN: 0,
	ARCHIVED: 1,
};

const UnreadMessagesChip = ({ count }) => (
	<Chip
		label={count}
		style={{
			backgroundColor: '#ff5252',
			color: '#fff',
			cursor: 'pointer',
			height: '24px',
			width: '24px',
		}}
	/>
);

const NewChannelChip = () => (
	<Chip
		label={'Nouveau'}
		style={{
			backgroundColor: '#3f51b5',
			color: '#fff',
			cursor: 'pointer',
		}}
	/>
);

const ChatListCompany = ({
	channelType,
	currentChannel,
	filteredChannels,
	isLoadingChannels,
	onSelectChannel,
	setChannelType,
}) => (
	<List
		disablePadding
		subheader={
			<ListSubheader disableGutters style={{ backgroundColor: '#fff' }}>
				<Tabs
					indicatorColor="primary"
					onChange={(e, value) => setChannelType(value)}
					textColor="primary"
					value={channelType}
					variant="fullWidth"
				>
					<Tab label="Open" />
					<Tab label="Archived" />
				</Tabs>
			</ListSubheader>
		}
	>
		{isLoadingChannels && (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress size={20} />
			</div>
		)}
		{filteredChannels.length ? (
			filteredChannels.map(
				({ channel, isMember, messagesUnreadCount }) => (
					<ListItem
						button
						divider
						key={channel.sid}
						onClick={() => onSelectChannel(channel)}
						style={
							!isMember || !!messagesUnreadCount
								? {
										backgroundColor: '#eee',
								  }
								: currentChannel &&
								  channel.sid === currentChannel.sid
									? { backgroundColor: '#ccc' }
									: {}
						}
					>
						{/* <ListItemIcon>
                                <Avatar>Y</Avatar>
                            </ListItemIcon> */}
						<ListItemText
							primary={
								<div
									style={{
										alignItems: 'center',
										display: 'flex',
									}}
								>
									{!channel.attributes.archived &&
										channel.attributes.locked && (
											<LockIcon
												color="action"
												fontSize="small"
												style={{ marginRight: '8px' }}
											/>
										)}
									<Typography
										style={{
											paddingTop: '4px',
										}}
									>
										{channel.friendlyName}
									</Typography>
								</div>
							}
							secondary={
								<DateTimeComponent
									dateTime={getChannelLastDate(channel)}
								/>
							}
							style={{ wordBreak: 'break-word' }}
						/>
						{isMember ? (
							!!messagesUnreadCount && (
								<UnreadMessagesChip
									count={messagesUnreadCount}
								/>
							)
						) : (
							<NewChannelChip />
						)}
						<ChevronRightIcon color="action" />
					</ListItem>
				)
			)
		) : (
			<Typography
				style={{
					display: 'flex',
					justifyContent: 'center',
					padding: '8px',
				}}
			>
				No channels
			</Typography>
		)}
	</List>
);

const getChannelLastDate = channel =>
	channel.state.lastMessage
		? channel.state.lastMessage.timestamp
		: channel.state.dateCreated;

const sortChannels = channels =>
	Object.keys(channels)
		.map(key => channels[key])
		.sort(({ channel: channelA }, { channel: channelB }) => {
			const aDate = getChannelLastDate(channelA);
			const bDate = getChannelLastDate(channelB);
			if (aDate > bDate) {
				return -1;
			} else if (aDate < bDate) {
				return 1;
			}
			return 0;
		});

export default compose(
	withProps(({ channels }) => ({ channels: sortChannels(channels) })),
	withState('channelType', 'setChannelType', CHANNEL_TYPES.OPEN),
	withProps(({ channels, channelType }) => ({
		filteredChannels: channels.filter(
			({ channel: { attributes } }) =>
				channelType === CHANNEL_TYPES.ARCHIVED
					? attributes.archived
					: !attributes.archived
		),
	}))
)(ChatListCompany);

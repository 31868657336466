// SESSION
export const CAN_ACTIVE_COMPANY = 'can active company';
export const CLEAR_SESSION = 'reinitialize session state';
export const HAS_INIT_TWILIO = 'has initialized twilio';
export const SET_CURRENT_COMPANY = "set logged user's company";
export const SET_CURRENT_USER = 'set logged user';
export const SET_CHANNELS = 'set fetched channels';
export const SET_CURRENT_CHANNEL = 'set current channel chant opened';
export const SET_TWILIO_CLIENT = 'set twilio client for current company';
export const SET_FIREBASE_COLLECTION =
	'set firebase collection for current company';
export const SET_PENDING_WORKFLOW = 'set pending workflow tree';

// SNACKBAR
export const OPEN_SNACKBAR = 'open snackbar';
export const CLOSE_SNACKBAR = 'dismiss snackbar';

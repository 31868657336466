import React from 'react';
import { compose, withProps } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import BlockIcon from '@material-ui/icons/Block';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListIcon from '@material-ui/icons/List';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import Tree, { TreeNode } from 'rc-tree';
import 'rc-tree/assets/index.css';
import './tree.css';

import {
	STEP_TYPES,
	constructChatStepKey,
	constructEmptyStepKey,
	constructStepKey,
	constructStepChoiceKey,
} from './utils';

const getEmptyTreeNode = ({ parentStepPath }) => (
	<TreeNode
		key={constructEmptyStepKey(parentStepPath)}
		icon={<BlockIcon />}
		title="empty"
	/>
);

const getChatTreeNode = ({ parentStepPath }) => (
	<TreeNode
		key={constructChatStepKey(parentStepPath)}
		icon={<ChatBubbleOutlineIcon />}
		title="chat"
	/>
);

const constructStep = ({ parentStepPath = '', steps, workflow }) => {
	if (workflow === STEP_TYPES.CHAT)
		return getChatTreeNode({ parentStepPath });

	const stepId = Object.keys(workflow)[0];

	if (!stepId) return getEmptyTreeNode({ parentStepPath });

	const { choices, id, slug, type } = steps.find(
		({ id }) => id === parseInt(stepId, 10)
	);

	if (type === STEP_TYPES.FORM) {
		return (
			<TreeNode
				key={constructStepKey(id)(parentStepPath)}
				icon={<TextFieldsIcon />}
				title={slug}
				isLeaf
			>
				{constructStep({
					parentStepPath: constructStepKey(id)(parentStepPath),
					// we could filter parent steps
					steps,
					workflow: workflow[id],
				})}
			</TreeNode>
		);
	}

	if (type === STEP_TYPES.CHOICES) {
		return (
			<TreeNode
				key={constructStepKey(id)(parentStepPath)}
				icon={<ListIcon />}
				title={slug}
				// isLeaf
			>
				{choices.map(({ slug }) => (
					<TreeNode
						icon={<KeyboardArrowRightIcon />}
						key={constructStepChoiceKey(slug)(id)(parentStepPath)}
						selectable={false}
						title={slug}
						disabled
					>
						{constructStep({
							parentStepPath: constructStepChoiceKey(slug)(id)(
								parentStepPath
							),
							steps,
							workflow: workflow[id][slug],
						})}
					</TreeNode>
				))}
			</TreeNode>
		);
	}
};

const WorkflowTree = ({
	expandedKeys,
	hasWorkflow,
	onSelectStep,
	previewStepPath,
	setExpandedKeys,
	steps,
	workflow,
}) => (
	<Paper style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
		<Typography variant="subheading">
			<b>Tree</b>
		</Typography>
		{hasWorkflow ? (
			<Tree
				onExpand={keys => setExpandedKeys(keys)}
				onSelect={keys => onSelectStep(keys[0])}
				expandedKeys={expandedKeys}
				selectedKeys={[previewStepPath]}
			>
				{constructStep({
					steps,
					workflow,
				})}
			</Tree>
		) : (
			<Typography variant="caption">
				Your workflow tree will appear here
			</Typography>
		)}
	</Paper>
);

export default compose(
	withProps(({ workflow }) => ({
		hasWorkflow: workflow && !!Object.keys(workflow).length,
	}))
)(WorkflowTree);

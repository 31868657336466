import React from 'react';
import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Step } from '../../models';

import BaseForm from './step-creator/BaseForm';
import { CHOICE_SEPARATOR, STEP_SEPARATOR, STEP_TYPES } from './utils';

const StepCreator = ({
	canSave,
	errors,
	fields,
	onClose,
	t,
	saveStep,
	step,
	updateSlug,
	updateStep,
}) => (
	<Dialog fullWidth onClose={onClose} open>
		<DialogTitle>
			{step.id ? `Edit step ${step.slug}` : 'Create a new step'}
		</DialogTitle>
		<DialogContent>
			<BaseForm
				errors={errors}
				fields={fields}
				step={step}
				t={t}
				updateSlug={updateSlug}
				updateStep={updateStep}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary">
				Cancel
			</Button>
			<Button onClick={saveStep} color="primary" disabled={!canSave}>
				Save
			</Button>
		</DialogActions>
	</Dialog>
);

export const canSaveChoice = ({ labels, slug }, languages) =>
	slug &&
	!slug.includes(CHOICE_SEPARATOR) &&
	!slug.includes(STEP_SEPARATOR) &&
	!languages.filter(lng => !labels[lng]).length;

const canSaveChoices = (choices, languages) =>
	choices.length >= 2 &&
	!choices.find(choice => !canSaveChoice(choice, languages));

export default compose(
	translate('y'),
	connect(({ session: { company: { languages } } }) => ({
		languages,
	})),
	withStateHandlers(
		({
			step = {
				slug: '',
				title: '',
				type: STEP_TYPES.FORM,
				choices: [],
				fields: [],
			},
		}) => ({
			errors: {},
			step,
		}),
		{
			updateStep: ({ step }) => (key, value) => ({
				step: { ...step, [key]: value },
			}),
			updateSlug: ({ errors, step }, { t }) => slug => {
				let slugError;
				if (!slug) {
					slugError = t('fields.required');
				} else {
					slugError =
						slug.includes(CHOICE_SEPARATOR) ||
						slug.includes(STEP_SEPARATOR)
							? t('fields.step.slug.separators')
							: '';
				}
				return {
					errors: {
						...errors,
						slug: slugError,
					},
					step: {
						...step,
						slug,
					},
				};
			},
		}
	),
	withProps(
		({
			errors,
			languages,
			step: { slug, title, type, choices, fields },
		}) => ({
			canSave:
				!errors.slug &&
				!!slug &&
				!!title &&
				(type === STEP_TYPES.FORM
					? !!fields.length
					: canSaveChoices(choices, languages)),
		})
	),
	withHandlers({
		saveStep: ({
			companyId,
			onClose,
			refreshSteps,
			step: { slug, id, title, choices, fields, type },
		}) => async () => {
			try {
				await Step.post({
					id,
					data: {
						slug,
						title,
						type,
						choices: type === STEP_TYPES.CHOICES ? choices : null,
						fields: type === STEP_TYPES.FORM ? fields : null,
						companyId,
					},
				});
				refreshSteps();
				onClose();
			} catch (e) {
				console.error(e);
			}
		},
	})
)(StepCreator);
